import React from 'react';
import Box from '../box/index.jsx';
import ImageLazy from '../image-lazy/image-lazy.jsx';
import style from './table.module.scss';

function Table({ className = '', data = [], keys = [] }) {
  return (
    <div className={`${style.compareTable} ${className}`}>
      <div className={style.compareTableHeadRow}>
        <div></div>
        {data.map((p) => {
          return (
            <div className={style.compareTableHeadCell} key={p.sn}>
              <ImageLazy market={p.market} sn={p.sn} alt={p.display_name} />
              <div className={style.compareTableHeadCellText}>
                {p.display_name}
              </div>
            </div>
          );
        })}
      </div>
      <Box className={style.compareTableBody}>
        <div className={style.compareTableBodyScroll}>
          {keys.map((key) => {
            return (
              <div className={style.compareTableBodyRow} key={key.key}>
                <div
                  className={`${style.compareTableBodyCell} ${style.compareTableBodyCellFirst}`}
                >
                  {key.title}
                </div>
                {data.map((p) => {
                  return (
                    <div
                      className={style.compareTableBodyCell}
                      key={`${p.sn} ${key.key}`}
                    >
                      {p[key.key]} %
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
}

export default Table;
