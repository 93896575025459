import { App, Button, ConfigProvider, theme } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import bgMusic from '../../../assets/sound/bgm.mp3';
import buttonSound from '../../../assets/sound/woosh-2-6471.mp3';
import {
  businessCaseAssitantIcon,
  ellipseIcon,
  gotflIcon,
  knowledgeHubIcon,
  aiTopDish,
  marketMediaPerformanceIcon,
  mixCraterIcon,
  soundIcon,
  soundOffIcon,
  trendsIcon,
} from '../../../components/icons/icons.jsx';
import LandingVideoSecond from '../../../components/landing-bg-video-second/landing-bg-video-second.jsx';
import Logo from '../../../components/logo/logo.jsx';
import Profile from '../../../components/profile/profile.jsx';
import GetInTouch from '../get-in-touch/get-in-touch.jsx';
import Modal from './modal/modal.jsx';

const Navbar = () => {
  const Trends = [
    { id: 1, title: 'Trends', path: '' },
    { id: 2, title: 'Trends Dial', path: '/trends-dial' },
    // { id: 2, title: 'Trends Dial', path: '/' },
  ];
  const FrontLine = [
    { id: 1, title: 'Get On The Front Line', path: '' },
    { id: 3, title: 'Consumer Theatre', path: '/consumertheater' },
    { id: 4, title: 'GOTFL Weena', path: '/gotflweena' },
    { id: 5, title: 'Knorr Frontline', path: '/knorr' },
    { id: 2, title: 'Frontline Time', path: '/frontlinetime' },
  ];

  const Crafter = [
    { id: 1, title: 'Mix Crafter', path: '' },
    { id: 5, title: 'Unmissable Pack AI', path: '/package-ai' },
    { id: 4, title: 'Concept AI ', path: '/conceptai' },
    {
      id: 2,
      title: 'Concept GPT ',
      path: 'https://designnode-azure.sba.cpp.unileversolutions.com/public-webapps/conceptgpt_nutrition/',
    },
    {
      id: 3,
      title: 'Nutrition Innoflex GPT',
      path: 'https://bnlwe-da04-d-930339-webapi-01.azurewebsites.net/',
    },
  ];

  const Market = [
    { id: 1, title: 'Market Performance', path: '' },
    { id: 2, title: 'VISTA', path: '/vista' },
    { id: 3, title: 'BG Cell Performance Details', path: '/bu-feedback' },
    { id: 4, title: 'Value Decomposition 2.0', path: '/value-decomposition' },
    {
      id: 5,
      title: 'Request Access to Value Decomposition 2.0',
      path: '/request-access-value-decomposition',
    },
    { id: 6, title: 'Ratings & Review', path: '/ratings-review' },
  ];

  const KnowledgeHub = [
    { id: 1, title: 'Knowledge Hub', path: '' },
    { id: 2, title: 'Plant Based', path: '/plantbased' },
    {
      id: 3,
      title: 'Commercializing Sustainability',
      path: '/commercializing-sustainability',
    },
    { id: 4, title: 'Nutrition Tree of Wisdom', path: '/knowledge-tree' },
    { id: 5, title: 'Pain points', path: '/painpoints' },
    { id: 5, title: 'Top Dish Menu', path: '/top-dish' },
  ];

  const AiTopDish = [
    {id: 1, title: 'AI Top Dish', path: '/ai-top-dish'},
  ];

  const BusinessCaseAssitant = [
    { id: 1, title: 'Business Case Assistant' },
    {
      id: 2,
      title: 'Business Case Target Setting',
      path: '/business-case-assistant',
    },
  ];

  const OccasionModule = [
    { id: 1, title: 'Occasion Module', path: '/occasion' },
  ];

  const TT10 = [{ id: 1, title: 'TT10', path: '/tt10' }];

  const mainNavigationRef = useRef(null);
  const lastFocusedElementRef = useRef(null);
  const getInTouchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const openNewGetInTouch = () => {
    getInTouchRef.current.open();
  };

  const togglePopup = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const [audio] = useState(new Audio(buttonSound));
  const [backgroundAudio] = useState(new Audio(bgMusic));

  useEffect(() => {
    if (open) {
      lastFocusedElementRef.current = document.activeElement;
      mainNavigationRef.current.setAttribute('aria-hidden', 'true');
    } else {
      if (lastFocusedElementRef.current) {
        lastFocusedElementRef.current.focus();
      }
      mainNavigationRef.current.removeAttribute('aria-hidden');
    }
  }, [open]);

  const toggleBackgroundMusic = () => {
    if (isMusicPlaying) {
      backgroundAudio.pause();
    } else {
      backgroundAudio.play();
    }
    setIsMusicPlaying(!isMusicPlaying);
  };

  return (
    <>
      <div className="main-nav">
        <LandingVideoSecond />
        <div className="landing-header">
          <Logo />
          <Profile />
        </div>
        <nav ref={mainNavigationRef}>
          <div className="main-navigation">
            <ul className="navigation-content navigation-content-1">
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(FrontLine);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(FrontLine);
                      audio.play();
                    }
                  }}
                >
                  <img src={gotflIcon} alt="frontline" className="frontline" />
                  <span onClick={togglePopup} className="main-navigation-text">
                    Get On The Front Line
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(Trends);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(Trends);
                      audio.play();
                    }
                  }}
                >
                  <img src={trendsIcon} alt="trends" className="trends" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    Trends <br /> &nbsp;
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(Market);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(Market);
                      audio.play();
                    }
                  }}
                >
                  <img src={marketMediaPerformanceIcon} alt="market&media" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    Market & Media Performance{' '}
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(Crafter);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(Crafter);
                      audio.play();
                    }
                  }}
                >
                  <img src={mixCraterIcon} alt="crafter" className="crafter" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    Mix Crafter <br /> &nbsp;{' '}
                  </span>
                </div>
              </li>
            </ul>
            <ul className="navigation-content navigation-content-2">
             <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                >
                  <img
                    src={aiTopDish}
                    alt="aitopdish"
                    height={'66px'}
                    onClick={() => window.location.href = AiTopDish[0].path}
                  />

                  <span className="main-navigation-text">
                    {' '}
                    Top Dish Trend Index <br /> &nbsp;{' '}
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(KnowledgeHub);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(KnowledgeHub);
                      audio.play();
                    }
                  }}
                >
                  <img src={knowledgeHubIcon} alt="knowledgehub" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    KnowledgeHub <br /> &nbsp;{' '}
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(BusinessCaseAssitant);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(BusinessCaseAssitant);
                      audio.play();
                    }
                  }}
                >
                  <img src={businessCaseAssitantIcon} alt="knowledgehub" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    Business Case Assistant{' '}
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(OccasionModule);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(OccasionModule);
                      audio.play();
                    }
                  }}
                >
                  <img src={knowledgeHubIcon} alt="knowledgehub" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    Occasion Module <br /> &nbsp;{' '}
                  </span>
                </div>
              </li>
              <li style={{ display: 'none' }}>
                <div
                  className="main-navigation-content"
                  tabIndex={0}
                  onClick={() => {
                    setOpen(!open);
                    setData(TT10);
                    audio.play();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setOpen(!open);
                      setData(TT10);
                      audio.play();
                    }
                  }}
                >
                  <img src={trendsIcon} alt="TT10" />
                  <span className="main-navigation-text" onClick={togglePopup}>
                    {' '}
                    TT10 <br /> &nbsp;{' '}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          {open && (
            <Modal content={data} handleClose={togglePopup} open={open} />
          )}
        </nav>
      </div>
      <div className="nav-legal-disclaimer">
        <p> All content in this platform is copyrighted © 2024 Unilever</p>
      </div>
      <div className="nav-contact">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#00a32e',
              colorError: '#eb0000',
              colorSuccess: '#06ca3d',
              fontFamily: 'unilever-shilling-regular',
            },
            algorithm: theme.darkAlgorithm,
          }}
        >
          <Button
            type="primary"
            className="nav-contact-button"
            onClick={openNewGetInTouch}
            role="button"
          >
            Get In Touch
          </Button>

          <App className="nav-get-in-touch-form">
            <GetInTouch ref={getInTouchRef} />
          </App>
        </ConfigProvider>
      </div>
      <div className="nav-music">
        <div className="rotate-ellipse">
          <img src={ellipseIcon} alt="ellipse" />
        </div>
        <img
          src={isMusicPlaying ? soundIcon : soundOffIcon}
          alt="sound"
          className="sound-icon icon-size"
          onClick={toggleBackgroundMusic}
        />
      </div>
    </>
  );
};

export default Navbar;
