import React from 'react';
import EqualSvg from '../assets/img/equal-y.svg';
import MinusSvg from '../assets/img/minus-r.svg';
import PlusSvg from '../assets/img/plus-g.svg';
import Box from './box2.jsx';
import style from './legend2.module.scss';

function PackageAiLegend({ className = '' }) {
  return (
    <Box className={className}>
      <div className={style.boxInner}>
        <div className={style.box11}>
          <div className={style.icon}>
            <img src={PlusSvg} />
          </div>
          <span className={style.text2}>
            If Unilever Brand score is greater than Competitor Brand score by 10
            percentage points or more
          </span>
        </div>
        <div className={style.box11}>
          <div className={style.icon}>
            <img src={EqualSvg} />
          </div>
          <span className={style.text2}>
            If Unilever Brand score is greater or less than Competitor Brand
            score by less than 10 percentage points
          </span>
        </div>
        <div className={style.box11}>
          <div className={style.icon}>
            <img src={MinusSvg} />
          </div>
          <span className={style.text2}>
            If Unilever Brand score is less than Competitor Brandscore by 10
            percentage points or more
          </span>
        </div>
      </div>
    </Box>
  );
}

export default PackageAiLegend;
