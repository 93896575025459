// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pDZJwwpbuvJwP31rb1mw{position:relative}.pDZJwwpbuvJwP31rb1mw:hover .SxYeV8NlV8P7RT9UBo4w{display:block}.SxYeV8NlV8P7RT9UBo4w{display:none;position:absolute;z-index:1001}.FSdHnTb_t2riN4uwhnSP{bottom:100%;left:50%;transform:translateX(-50%)}.yAn3O4oD5DwQtzYfdLAW{top:100%;left:50%;transform:translateX(-50%)}.ToVG2mhRMPsDMJC4Mrw5{top:100%;right:0}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/dropdown.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEE,kDACE,aAAA,CAKN,sBACE,YAAA,CACA,iBAAA,CACA,YAAA,CAGF,sBACE,WAAA,CACA,QAAA,CACA,0BAAA,CAGF,sBACE,QAAA,CACA,QAAA,CACA,0BAAA,CAGF,sBACE,QAAA,CACA,OAAA","sourcesContent":[".box {\n  position: relative;\n  &:hover {\n    .dropdown {\n      display: block;\n    }\n  }\n}\n\n.dropdown {\n  display: none;\n  position: absolute;\n  z-index: 1001;\n}\n\n.dropdown-top{\n  bottom: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.dropdown-bottom{\n  top: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.dropdown-bottom-left{\n  top: 100%;\n  right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `pDZJwwpbuvJwP31rb1mw`,
	"dropdown": `SxYeV8NlV8P7RT9UBo4w`,
	"dropdown-top": `FSdHnTb_t2riN4uwhnSP`,
	"dropdown-bottom": `yAn3O4oD5DwQtzYfdLAW`,
	"dropdown-bottom-left": `ToVG2mhRMPsDMJC4Mrw5`
};
export default ___CSS_LOADER_EXPORT___;
