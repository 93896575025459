import { App } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useMemo, useState } from 'react';
import { downloadImg, downloadStackData } from '../../../apis/file';
import { getChart } from '../../../apis/serve/index.js';
import ColorsLight from '../../../assets/json/colors-light.json';
import Colors from '../../../assets/json/colors.json';
import style from './chart.module.scss';
import ChartContainer from './container.jsx';

function Chart({
  name,
  tabName,
  tab2Name,
  filter,
  compare,
  hasCompare,
  size,
  title,
  sortList,
  hiddenItems = [],
  appendSaveDataFn,
  chartKey,
  userSelectChart,
  userSelectChartKey,
  userSelectChartValue,
  onUserSelectChart,
}) {
  const app = App.useApp();
  const { message } = app;

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [count, setCount] = useState(0);
  const [compareCount, setCompareCount] = useState(0);
  const [compareData, setCompareData] = useState([]);
  const [needLoad, setNeedLoad] = useState(true);
  const [isIntersecting, setIntersecting] = useState(false);
  const [showDataLabels, setShowDataLabels] = useState(false);

  useEffect(() => {
    setNeedLoad(true);
  }, [filter, compare, hasCompare, userSelectChart]);

  async function read() {
    if (loading) return;
    setLoading(true);
    try {
      const ff = { ...filter };
      if (userSelectChart !== name && userSelectChartKey) {
        ff[userSelectChartKey] = [userSelectChartValue];
      }
      const { data } = await getChart(name, ff);
      if (data) {
        const { data: chartData, total } = data;
        const dd = chartData.filter((e) => !hiddenItems.includes(e[0]));
        setCount(total);
        if (!sortList) {
          const sortedData = dd.sort((a, b) => a[1] - b[1]);
          setFilterData(sortedData);
        } else {
          const sortedData = sortList.map((e) => {
            const fd = dd.find((e1) => e1[0] === e);
            if (!fd) return [e, 0];
            else return fd;
          });
          setFilterData(sortedData);
        }
      } else {
        setFilterData([]);
      }
      if (hasCompare) {
        const cf = { ...compare };
        if (userSelectChart && userSelectChart !== name) {
          cf[userSelectChartKey] = [userSelectChartValue];
        }
        const { data: cd } = await getChart(name, cf);
        if (cd) {
          const { data: chartData, total } = cd;
          setCompareCount(total);
          setCompareData(chartData);
        } else {
          setCompareData([]);
        }
      } else {
        setCompareData([]);
      }
    } catch (err) {
      console.error('read chart data error', name, err);
      message.error('Read chart data error');
    }
    setLoading(false);
    setNeedLoad(false);
  }

  useEffect(() => {
    if (needLoad && isIntersecting) {
      void read();
    }
  }, [needLoad, isIntersecting]);

  const option = useMemo(() => {
    const o = {
      legend: {
        icon: 'circle',
        bottom: '0',
        left: 0,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: 'rgba(255,255,255, 1)',
          // fontSize: '8px',
        },
        selectedMode: false,
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        valueFormatter(value) {
          return `${value.toFixed(2)}%`;
        },
      },
      grid: {
        top: '3%',
        left: '0',
        right: '5%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: hasCompare ? ['Current', 'Compare'] : ['Current'],
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#fff',
          // fontSize: '8px',
          fontWeight: 'bold',
        },
        axisLine: {
          lineStyle: {
            color: '#00A32E',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          // fontSize: '8px',
          color: '#fff',
          formatter(value) {
            return `${value.toFixed(0)}%`;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#63636369',
            width: 1,
          },
        },
      },
      color: Colors,
      series: [],
    };

    function processUserSelectData(e, c, ci) {
      if (userSelectChart !== name) return e;
      if (userSelectChartValue === c) return e;
      return {
        value: e,
        itemStyle: {
          color: ColorsLight[ci],
        },
      };
    }

    const serieOption = {
      type: 'bar',
      stack: 'total',
      label: {
        show: showDataLabels,
        formatter: (params) => {
          return params.value.toFixed(2) + '%';
        },
      },
      // itemStyle: {
      //   color: '#00A32E',
      // },
    };
    // set series
    if (hasCompare) {
      const cs = filterData.map((e) => e[0]);
      compareData.forEach((e) => {
        if (cs.includes(e[0])) return;
        cs.push(e[0]);
      });
      o.series = cs.map((e, i) => {
        const fd = filterData.find((e1) => e1[0] === e) ?? ['', 0.0];
        const cd = compareData.find((e1) => e1[0] === e) ?? ['', 0.0];
        return {
          ...serieOption,
          name: e,
          data: [
            processUserSelectData((fd[1] / count) * 100.0, e, i),
            processUserSelectData((cd[1] / compareCount) * 100.0, e, i),
          ],
        };
      });
    } else {
      o.series = filterData.map((e, i) => {
        return {
          ...serieOption,
          name: e[0],
          data: [processUserSelectData((e[1] / count) * 100.0, e[0], i)],
        };
      });
    }
    return o;
  }, [filterData, compareData, showDataLabels, userSelectChart]);

  const [echarts, setEcharts] = useState(null);

  useEffect(() => {
    if (!echarts) return;
    function handleClick(params) {
      const { seriesName: value } = params;
      if (userSelectChart === name && userSelectChartValue === value) {
        onUserSelectChart('', '', '');
      } else {
        onUserSelectChart(name, chartKey, value);
      }
    }
    echarts.on('click', handleClick);
    return () => {
      echarts.off('click', handleClick);
    };
  }, [echarts, userSelectChart, userSelectChartValue]);

  function initSaveDataFn() {
    if (!appendSaveDataFn) return;
    appendSaveDataFn((wb) => {
      if (!count) return;
      downloadStackData(
        title,
        'xlsx',
        count,
        filterData,
        hasCompare,
        compareCount,
        compareData,
        wb
      );
    });
  }
  useEffect(() => {
    initSaveDataFn();
  }, [appendSaveDataFn]);

  return (
    <ChartContainer
      name={name}
      tabName={tabName}
      tab2Name={tab2Name}
      title={title}
      count={count}
      size={size}
      loading={loading}
      setIntersecting={setIntersecting}
      onSaveImg={() => downloadImg(title, echarts)}
      onSaveExcel={() =>
        downloadStackData(
          title,
          'xlsx',
          count,
          filterData,
          hasCompare,
          compareCount,
          compareData
        )
      }
      onSaveCsv={() =>
        downloadStackData(
          title,
          'csv',
          count,
          filterData,
          hasCompare,
          compareCount,
          compareData
        )
      }
      onDataLabelChange={setShowDataLabels}
    >
      <ReactECharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        className={style.chart}
        onChartReady={setEcharts}
      ></ReactECharts>
    </ChartContainer>
  );
}

export default Chart;
