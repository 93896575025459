import { message } from 'antd';
import React, { useCallback } from 'react';
import BtnPng from '../assets/img/btn.png';
import style from './btn.module.scss';

function PackageAiBtn({ children, href }) {
  const onClick = useCallback(() => {
    if (!href) {
      message.warning('Coming soon');
    } else window.open(href, '_self');
  }, [href]);

  return (
    <div className={style.btn} onClick={onClick}>
      <img className={style.btnImg} src={BtnPng} />
      <div className={style.btnText}>{children}</div>
    </div>
  );
}

export default PackageAiBtn;
