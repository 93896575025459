// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FGWE6cInVNQSNDtnOPKg{width:100% !important}.rt7avW60g0ZQoRuAfJ6N{position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/ai-top-dish/ai-top-dish.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CAGF,sBACI,iBAAA","sourcesContent":[".fullContainer {\n  width: 100% !important;\n}\n\n.filterApply {\n    position: relative;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullContainer": `FGWE6cInVNQSNDtnOPKg`,
	"filterApply": `rt7avW60g0ZQoRuAfJ6N`
};
export default ___CSS_LOADER_EXPORT___;
