/* eslint-disable import/no-unresolved */
import bgVideo from '@/assets/videos/trend-dial-video.mp4';
import React from 'react';
import style from './bg.module.scss';

const VideoBg = () => {
  return (
    <div className={style.videoBox}>
      <video
        autoPlay="autoPlay"
        muted={true}
        loop="loop"
      >
        <source src={bgVideo} />
      </video>
    </div>
  );
};

export default VideoBg;
