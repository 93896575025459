// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1QtGZpKxA2CoPENTzJT{height:100%;padding:30px;box-sizing:border-box;overflow-x:hidden;overflow-y:auto}.WJBuKTKP1puxMjvPrz2U{margin-top:20px;display:flex;justify-content:center}.kP3vpl2A9jUheG7m7FHz{margin:10px 0 6px;display:flex;align-items:center}.kP3vpl2A9jUheG7m7FHz:first-child{margin-top:0}.NJmtoheywSQFBLDy29Yl{margin-right:10px;border-left:solid 12px #00a32e;border-top:solid 10px rgba(0,0,0,0);border-bottom:solid 10px rgba(0,0,0,0)}.jqSCjKZd9NfgLQED0om_{font-size:16px;font-weight:400;line-height:22.3px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/text.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CAEA,kCACE,YAAA,CAIJ,sBACE,iBAAA,CACA,8BAAA,CACA,mCAAA,CACA,sCAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".textBox {\n  height: 100%;\n  padding: 30px;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.textBtn {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.formLabel {\n  margin: 10px 0 6px;\n  display: flex;\n  align-items: center;\n\n  &:first-child {\n    margin-top: 0;\n  }\n}\n\n.formIcon {\n  margin-right: 10px;\n  border-left: solid 12px #00a32e;\n  border-top: solid 10px transparent;\n  border-bottom: solid 10px transparent;\n}\n\n.formLabelContent {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22.3px;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBox": `D1QtGZpKxA2CoPENTzJT`,
	"textBtn": `WJBuKTKP1puxMjvPrz2U`,
	"formLabel": `kP3vpl2A9jUheG7m7FHz`,
	"formIcon": `NJmtoheywSQFBLDy29Yl`,
	"formLabelContent": `jqSCjKZd9NfgLQED0om_`
};
export default ___CSS_LOADER_EXPORT___;
