import React from 'react';
import classNames from 'classnames';
import style from './preview-popup.module.scss';
import closeSvg from '../../../../assets/images/gotfl-weena/close-icon.svg';
import download2Svg from '../../../../assets/images/gotfl-weena/download-2.svg';
import arrowLeftSvg from '../../../../assets/images/gotfl-weena/arrow-left.svg';

import { Modal } from 'antd';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import { downloadFile } from '../../hooks/utils.js';
import { isWinDpr1 } from '../../../../util/utils.jsx';

const PreviewPopup = ({open, onClose, detail}) => {
  return <Modal open={open} closeIcon={false} width="1100px" centered={isWinDpr1()} footer={false} className={style.customModalWrap}  destroyOnClose={true}>
    <img src={closeSvg} className={classNames(style.closeIcon)} onClick={onClose} />
    <BorderContainer id="intro_1">
      <div className={classNames(style.contentWrap)}>
        <div className={classNames(style.fileName)}>
          <img src={arrowLeftSvg} style={{marginRight: '10px'}}></img>
          {detail?.name}
        </div>

        <div className={classNames(style.previewContent)}>
          {
            detail?.type === 'video' &&  <video src={detail?.fileUrl} controls style={{height: '100%'}}></video>
          }
          {
            detail?.type === 'picture' &&  <img src={detail?.fileUrl} style={{height: '100%'}}></img>
          }
        </div>

        <div className={classNames(style.downloadBox)}>
          <img src={download2Svg} alt="" style={{cursor: 'pointer'}} onClick={() => downloadFile(detail)}/>
        </div>
      </div>
    </BorderContainer>
  </Modal>;
};

export default PreviewPopup;