export { default as gotflIcon } from '../../assets/images/svg/get-on-the-front-line-icon.svg';
export { default as gotflIconGreen } from '../../assets/images/svg/get-on-the-front-line-icon-green.svg';
export { default as trendsIcon } from '../../assets/images/svg/trends.svg';
export { default as trendsIconGreen } from '../../assets/images/svg/trends-green-icon.svg';
export { default as mixCraterIcon } from '../../assets/images/svg/mix-crafter-icon.svg';
export { default as mixCraterIconGreen } from '../../assets/images/svg/mix-crafter-icon-green.svg';
export { default as marketMediaPerformanceIcon } from '../../assets/images/svg/market-media-performance-icon.svg';
export { default as marketMediaPerformanceIconGreen } from '../../assets/images/svg/market-media-performance-icon-green.svg';
export { default as knowledgeHubIcon } from '../../assets/images/svg/knowledge-hub-icon.svg';
export { default as knowledgeHubIconGreen } from '../../assets/images/svg/knowledge-hub-icon-green.svg';
export { default as conceptAiIcon } from '../../assets/images/svg/concept-ai-icon.svg';
export { default as conceptAiIconGreen } from '../../assets/images/svg/concept-ai-icon-green.svg';
export { default as chartIcon } from '../../assets/images/svg/chart-icon.svg';
export { default as chartIconGreen } from '../../assets/images/svg/chart-icon-green.svg';
export {default as businessCaseAssitantIcon} from '../../assets/images/svg/house_percentage.svg';
export {default as businessCaseAssitantIconGreen} from '../../assets/images/svg/house_percentage_green.svg';
export {default as ExcelIcon} from '../../assets/images/svg/excel_icon_svg.svg';

export { default as collapsibleArrow } from '../../assets/images/svg/chevron-right.svg';
export { default as collapsibleArrowGreen } from '../../assets/images/svg/chevron-right-green.svg';
export { default as camera } from '../../assets/images/svg/camera-icon.svg';
export { default as gallery } from '../../assets/images/svg/gallery-white.svg';
export { default as deleteIcon } from '../../assets/images/svg/delete.svg';
export { default as download } from '../../assets/images/svg/download.svg';
export { default as edit } from '../../assets/images/svg/edit.svg';
export { default as line } from '../../assets/images/svg/line-icon.svg';

export { default as closeIcon } from '../../assets/images/svg/close-icon.svg';
export { default as shareIcon } from '../../assets/images/svg/share-icon.svg';
export { default as commentIcon } from '../../assets/images/svg/comment-icon.svg';
export { default as favoriteIcon } from '../../assets/images/svg/favorite-icon.svg';

export { default as imageIcon } from '../../assets/images/svg/image-icon.svg';
export { default as imagePlaceholder } from '../../assets/images/svg/image-placeholder.svg';
export { default as cancelIcon } from '../../assets/images/svg/cancel-icon.svg';
export { default as lineCameraIcon } from '../../assets/images/svg/line-camera-icon.svg';
export { default as sendIcon } from '../../assets/images/svg/send-icon.svg';
export { default as leftLineIcon } from '../../assets/images/svg/chevron-left.svg';
export { default as favoriteGreenIcon } from '../../assets/images/svg/favorite-green-icon.svg';
export { default as photoIcon } from '../../assets/images/svg/photo-icon.svg';
export { default as arrowLeft } from '../../assets/images/svg/arrow-left.svg';
export { default as arrowRight } from '../../assets/images/svg/arrow-right.svg';
export { default as arrowUp } from '../../assets/images/svg/arrow-up-tiny.svg';
export { default as arrowUpGreen } from '../../assets/images/svg/arrow-up-green.svg';
export {default as arrowDown} from '../../assets/images/svg/down-arrow.svg';
export {default as arrowDownGreen} from '../../assets/images/svg/down-arrow-green.svg';
export { default as Filter } from '../../assets/images/svg/Filter.svg';
export { default as filterGreen } from '../../assets/images/svg/filter-green.svg';
export { default as userProfileIcon } from '../../assets/images/svg/user-profile.svg';
export { default as chevronDownIcon } from '../../assets/images/svg/chevron-down-icon.svg';
export { default as chevronUp } from '../../assets/images/svg/chevron-down.svg';
export { default as filters } from '../../assets/images/svg/filters-icon.svg';
export { default as searchIcon } from '../../assets/images/svg/Search.svg';
export { default as searchIconGreen } from '../../assets/images/svg/search-green.svg';
export { default as useProfileWhiteIcon } from '../../assets/images/svg/user-profile.svg';
export { default as searchGreenIcon } from '../../assets/images/svg/search-green-icon.svg';
export { default as backwardIcon } from '../../assets/images/svg/backward-icon.svg';
export { default as trendingup } from '../../assets/images/svg/trending_up.svg';
export { default as grade } from '../../assets/images/svg/grade.svg';
export { default as fileCopyIcon } from '../../assets/images/svg/file-copy.svg';
export { default as outLookIcon } from '../../assets/images/svg/Icon-Outlook.svg';
export { default as noImageUploaded } from '../../assets/images/svg/No-image-uploaded.svg';
export { default as likeIcon } from '../../assets/images/svg/like-icon.svg';
export { default as cancelGreenIcon } from '../../assets/images/svg/cancel-green-icon.svg';
export { default as modalRightArrow } from '../../assets/images/svg/modal-arrow.svg';
export { default as modalLeftArrow } from '../../assets/images/svg/modal-arrow1.svg';
export { default as downArrowGreenIcon } from '../../assets/images/svg/down-arrow-green-icon.svg';
export { default as searchTikTok } from '../../assets/images/svg/search-tik-tok.svg';
export { default as competitorInnovation } from '../../assets/images/svg/competitor-innovation.svg';
export { default as videoIcon } from '../../assets/images/svg/Video-Icon.svg';
export { default as userProfile } from '../../assets/images/svg/user-circle.svg';
export {default as ellipseIcon} from '../../assets/images/svg/EllipseIcon.svg';
export {default as soundIcon} from '../../assets/images/svg/sound ON Icon.svg';
export {default as rectangle} from '../../assets/images/svg/RectangleIcon.svg';
export {default as soundOffIcon} from '../../assets/images/svg/sound OFF Icon.svg';
export {default as trendingDown} from '../../assets/images/svg/trending_down.svg';

export {default as dialShare} from '../../assets/images/svg/dial-share.svg';

export {default as borderCornerSmallUp} from '../../assets/images/svg/border-corner-small-top.svg';
export {default as borderCornerSmallRight} from '../../assets/images/svg/border-corner-small-right.svg';
export {default as borderCornerSmallDown} from '../../assets/images/svg/border-corner-small-down.svg';
export {default as borderCornerSmallLeft} from '../../assets/images/svg/border-corner-small-left.svg';

export {default as borderCornerUp} from '../../assets/images/svg/border-corner-top.svg';
export {default as borderCornerRight} from '../../assets/images/svg/border-corner-right.svg';
export {default as borderCornerDown} from '../../assets/images/svg/border-corner-down.svg';
export {default as borderCornerLeft} from '../../assets/images/svg/border-corner-left.svg';
export {default as triangleRight} from '../../assets/images/svg/triangle-right.svg';
export {default as dialArrowLeft} from '../../assets/images/svg/dial-arrow-left.svg';
export {default as dialExport} from '../../assets/images/svg/dial-export-icon.svg';
export {default as aiTopDish} from '../../assets/images/svg/ai-top-dish.svg';
export {default as aiTopDishEngine} from '../../assets/images/svg/ai-top-dish-engine.svg';
export {default as knorIcon} from '../../assets/images/svg/knor-icon.svg';
export {default as hellmonsIcon} from '../../assets/images/svg/hellmanns-icon.svg';
export {default as rectangleDownIcon} from '../../assets/images/svg/vector-down-icon.svg';
export {default as aiTopDishWhite} from '../../assets/images/svg/ai-top-dish-white-icon.svg';
export {default as aiTopDishGreen} from '../../assets/images/svg/ai-top-dish-green-icon.svg';
export {default as aiTopDishInfo} from '../../assets/images/svg/info-topdish-icon.svg';
export {default as aiTopDishShare} from '../../assets/images/svg/share-topdish-icon.svg';
export {default as aiTopUnileverLogo} from '../../assets/images/svg/unilever-logo.svg';
export {default as aiTopCancelBtn} from '../../assets/images/svg/cancel-btn.svg';
export {default as trendingIcon} from '../../assets/images/svg/trending-icon.svg';
export {default as trendingUpIcon} from '../../assets/images/svg/trending-up-icon.svg';
export {default as trendingDownIcon} from '../../assets/images/svg/trending-down-icon.svg';
export {default as aiTopGoogleLogo} from '../../assets/images/svg/google-logo.svg';
export {default as aiTopMetaLogo} from '../../assets/images/svg/meta-logo.svg';
export {default as aiTopTiktokLogo} from '../../assets/images/svg/tiktok-logo.svg';
export {default as indexScoreLogo} from '../../assets/images/svg/index-score.svg';
export {default as trendLifeStageLogo} from '../../assets/images/svg/trend-lifestage.svg';
export {default as categoryRelavanceLogo} from '../../assets/images/svg/category-relavance.svg';
export {default as graphTrendIndexLogo} from '../../assets/images/svg/trend-index-curve-icon.svg';
export {default as topDishBorderCornerUp} from '../../assets/images/svg/topdish-border-corner-top.svg';
export {default as topDishBorderCornerDown} from '../../assets/images/svg/topdish-border-corner-down.svg';
export {default as noDataGraph} from '../../assets/images/svg/nodata-img.svg';
export {default as howToUseTool} from '../../assets/images/ai-top-dish/how_to_use_tool.png';
