import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';
import classNames from 'classnames';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import BorderContainerDark from '../border-container/border-container-dark.jsx';

const ModalDetail = ({ open, data, closeModal }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const thumbnailRef = useRef(null);
  const images = [
    'https://via.placeholder.com/600x600?text=Image+1',
    'https://via.placeholder.com/600x600?text=Image+2',
    'https://via.placeholder.com/600x600?text=Image+3',
    'https://via.placeholder.com/600x600?text=Image+4',
  ];

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
    carouselRef.current.goTo(index);
  };

  const handlePrevClick = () => {
    thumbnailRef.current.scrollBy({ left: -280, behavior: 'smooth' });
  };

  const handleNextClick = () => {
    thumbnailRef.current.scrollBy({ left: 280, behavior: 'smooth' });
  };

  return Object.keys(data).length === 1 ? null : (
    <div className={classNames(styles.modalGreen, { [styles.open]: open })}>
      <div className={styles.anmateContent}>
        <div className={styles.modalContent}>
          <BorderContainerDark id="modal" breath={true}>
            <div className={styles.modalHeader}>
              <div className={styles.textWrap}>123123</div>
              <div className={styles.closeButton} onClick={closeModal}></div>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.modalImgSlide}>
                <Carousel
                  className={styles.modalImgSlideImg}
                  afterChange={(current) => setCurrentSlide(current)}
                  dots={false}
                  ref={carouselRef}
                >
                  {images.map((image, index) => (
                    <div key={index}>
                      <img
                        className={styles.imgObj}
                        src={image}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                  ))}
                </Carousel>
                <div className={styles.slideWrap}>
                  <LeftOutlined
                    className={styles.arrPrev}
                    onClick={handlePrevClick}
                  />
                  <div className={styles.thumbnail} ref={thumbnailRef}>
                    {images.map((image, index) => (
                      <div
                        className={classNames(styles.imgItem, {
                          [styles.active]: currentSlide === index,
                        })}
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img src={image} alt={`Thumbnail ${index + 1}`} />
                      </div>
                    ))}
                  </div>
                  <RightOutlined
                    className={styles.arrNext}
                    onClick={handleNextClick}
                  />
                </div>
              </div>
              <div className={classNames(styles.modalDetailWrap)}>
                <dl>
                  <dt>Company</dt>
                  <dd>Ceres Organics, Australia</dd>
                </dl>
                <dl>
                  <dt>Category</dt>
                  <dd>Sauces & Seasonings &gt; Cooking Sauces</dd>
                </dl>
                <dl>
                  <dt>Market</dt>
                  <dd>Australia</dd>
                </dl>
                <dl>
                  <dt>Launch Type</dt>
                  <dd>New Variety/Range Extension</dd>
                </dl>
                <dl>
                  <dt>Date published</dt>
                  <dd>March 2024</dd>
                </dl>
                <dl>
                  <dt>Positioning Claims</dt>
                  <dd>
                    Ethical - Environmentally Friendly Product, Ethical -Human,
                    Ethical - Sustainable (Habitat/Resources),Organic, Vegan/No
                    Animal Ingredients
                  </dd>
                </dl>
                <dl>
                  <dt>Product Description</dt>
                  <dd>
                    Organic Coconut Milk (60%), Water, Organic Coconut Sugar,
                    Sea Salt, Guar Gum
                  </dd>
                </dl>
              </div>
            </div>
          </BorderContainerDark>
        </div>
      </div>
      <div className={styles.modalMask}></div>
    </div>
  );
};

ModalDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalDetail;
