// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lV_HBn5PZT3REnEzrPmN{position:relative;width:140px;height:140px;display:flex;justify-content:center;align-items:center;margin:0 auto;z-index:12}.lV_HBn5PZT3REnEzrPmN img{display:block;width:100%;height:100%;object-fit:contain}.lV_HBn5PZT3REnEzrPmN .JP1AwbpvtAyba2vbmeTe{height:140px;width:100%}.Ve4D9tkmLMC1DM3EcaAk{position:relative;width:135px;height:135px;display:flex;justify-content:center;align-items:center;margin:7px auto 0 auto;z-index:12}.Ve4D9tkmLMC1DM3EcaAk img{display:block;width:100%;height:100%;object-fit:contain}.Ve4D9tkmLMC1DM3EcaAk .JP1AwbpvtAyba2vbmeTe{height:135px;width:135px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-inspiration/components/image-lazy/image-lazy.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,UAAA,CACA,0BACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CAEF,4CACE,YAAA,CACA,UAAA,CAKN,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,0BACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CAEF,4CACE,YAAA,CACA,WAAA","sourcesContent":[".imgWrap {\n    position: relative;\n    width: 140px;\n    height: 140px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n    z-index: 12;\n    img {\n      display: block;\n      width: 100%;\n      height: 100%;\n      object-fit: contain;\n    }\n    .placeholderImg {\n      height: 140px;\n      width: 100%;\n    }\n  }\n\n\n.imgWrapCompare {\n  position: relative;\n  width: 135px;\n  height: 135px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 7px auto 0 auto;\n  z-index: 12;\n  img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n  .placeholderImg {\n    height: 135px;\n    width: 135px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrap": `lV_HBn5PZT3REnEzrPmN`,
	"placeholderImg": `JP1AwbpvtAyba2vbmeTe`,
	"imgWrapCompare": `Ve4D9tkmLMC1DM3EcaAk`
};
export default ___CSS_LOADER_EXPORT___;
