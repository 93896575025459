import { App, ConfigProvider, message, theme } from 'antd';
import React, { useCallback, useState } from 'react';
import { postFile } from '../../apis/file/index.js';
import Tab from '../../components/box-tab.jsx';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import { sleep } from '../../utils/time';
import Confirm from './confirm.jsx';
import Cursor from './cursor.jsx';
import Editor from './editor.jsx';
import style from './index.module.scss';
import Library from './library.jsx';
import Text from './text.jsx';
import Tool from './tool.jsx';
// import Upload from './upload.jsx';

function PackageAiView() {
  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const url = context.edit.imageUrl;

  const [loading, setLoading] = useState(false);
  // active tab
  const [active, setActive] = useState('library');
  // draw data
  const [data, setData] = useState(context.update?.data ?? []);
  const [drawRef, setDrawRef] = useState(null);
  // cursor on editor
  const [cursor, setCursor] = useState(1);
  // editor scale
  const [scale, setScale] = useState(1);
  // reset editor
  const [resetCount, setResetCount] = useState(0);

  const addImage = useCallback(
    (url, size) => {
      setData((dd) => {
        const nd = dd.map((e) => ({ ...e, selected: false }));
        nd.push({
          type: 'image',
          url,
          x: 50,
          y: 50,
          width: size.width / scale,
          height: size.height / scale,
          selected: false,
        });
        return nd;
      });
    },
    [setData, scale]
  );

  const addText = useCallback(
    (options) => {
      const {
        text,
        fontSize = 16,
        fontFamily,
        color = '#00a32e',
        textAlign = 'left',
      } = options;
      if (!text) throw new Error('Must input text');
      setData((dd) => {
        const nd = dd.map((e) => ({ ...e, selected: false }));
        nd.push({
          type: 'text',
          text,
          x: 50,
          y: 50,
          fontSize: fontSize,
          fontFamily,
          textAlign,
          color,
          width: 100 / scale,
        });
        return nd;
      });
    },
    [setData, scale]
  );

  function updateText(index, options) {
    const {
      text,
      fontSize = 16,
      fontFamily,
      color = '#00a32e',
      textAlign = 'left',
    } = options;
    if (!text) {
      // if text is empty, remove it
      setData((dd) => {
        const d = [...dd];
        d.splice(index, 1);
        return d;
      });
    } else {
      setData((dd) => {
        const d = [...dd];
        d[index] = {
          ...d[index],
          text,
          fontSize,
          fontFamily,
          textAlign,
          color,
        };
        return d;
      });
    }
  }

  function onBoxClick(ev) {
    if (ev.target.tagName === 'CANVAS') return;
    setData((data) => {
      return data.map((e) => ({ ...e, selected: false }));
    });
  }

  async function onRender() {
    if (!drawRef) return;
    if (loading) return;
    setLoading(true);
    try {
      setData((dd) => dd.map((e) => ({ ...e, selected: false })));
      await sleep(1000);

      const drawUrl = drawRef.toDataURL();

      // use buffer
      const [ds, bs64] = drawUrl.split(',');
      const mime = ds.split(':')[1].split(';')[0];
      const bs = atob(bs64);
      const ba = new Uint8Array(bs.length);
      for (let i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }

      let fileName = 'package-ai-generation';
      if (mime === 'image/jpeg') {
        fileName += '.jpg';
      } else if (mime === 'image/png') {
        fileName += '.png';
      } else {
        fileName += '.png';
      }

      // upload
      const file = new File([ba], fileName, {
        type: mime,
      });
      const { data: fd } = await postFile(file);

      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          ...context,
          update: {
            data: data,
            url: fd.url,
          },
        })
      );
      window.open('/package-ai-generation4', '_self');
    } catch (err) {
      console.error('generate image error', err);
      message.error('Generate image error');
    }
    setLoading(false);
  }

  function onClear() {
    setData([]);
    onReset();
  }

  function onReset() {
    setScale(1);
    setResetCount((c) => c + 1);
  }

  if (!url) {
    message.error('Please select a package first');
    window.open('/package-ai-generation', '_self');
    return;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={3}>
          <div className={style.box}>
            <Box className={style.box1} loading={loading} onClick={onBoxClick}>
              <Editor
                url={url}
                scale={scale}
                setScale={setScale}
                cursor={cursor}
                resetCount={resetCount}
                data={data}
                setData={setData}
                setDrawRef={setDrawRef}
                setLoading={setLoading}
              />
              <Tool setScale={setScale} onClear={onClear} onReset={onReset} />
              <Cursor cursor={cursor} setCursor={setCursor} />
              <div className={style.info1}>
                * Due to privacy legislation all insights & photos / videos can
                only be used internally,
                <br />
                and only after verbal consent from the audience. Ideally, no
                personal data is
                <br />
                obtained and any personal data obtained anonymized.*
              </div>
            </Box>
            <Tab
              className={style.box2}
              active={active}
              setActive={setActive}
              options={[
                {
                  label: 'Library',
                  value: 'library',
                  children: <Library addImage={addImage} />,
                },
                {
                  label: 'Text',
                  value: 'text',
                  children: (
                    <Text
                      data={data}
                      addText={addText}
                      updateText={updateText}
                      scale={scale}
                    />
                  ),
                },
                {
                  label: 'Confirm',
                  value: 'confirm',
                  children: <Confirm onConfirm={onRender} />,
                },
              ]}
              // headerRight={active === 'library' && <Upload />}
            ></Tab>
          </div>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
