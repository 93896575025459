import style from './dropdown.module.scss';
import React, { useMemo } from 'react';
import Box from './box.jsx';

function PackageAiDropdown({
  className = '',
  children,
  item,
  direction = 'bottom',
}) {
  const directionClass = useMemo(() => {
    return style[`dropdown-${direction}`];
  }, [direction]);

  return (
    <div className={`${style.box} ${className}`}>
      <div className={style.trigger}>{children}</div>
      <div className={`${style.dropdown} ${directionClass}`}>
        <Box>{item}</Box>
      </div>
    </div>
  );
}

export default PackageAiDropdown;
