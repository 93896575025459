import { App, ConfigProvider, message, theme } from 'antd';
import React, { useState } from 'react';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import style from './index.module.scss';

function PackageAiView() {
  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const url = context.update.url;

  const [downloadLoading, setDownloadLoading] = useState(false);
  async function onDownload() {
    if (downloadLoading) return;
    setDownloadLoading(true);
    try {
      const blob = await fetch(url, { mode: 'cors' }).then((r) => r.blob());
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      if (blob.type === 'image/png') {
        a.download = 'image.png';
      } else if (blob.type === 'image/jpeg') {
        a.download = 'image.jpg';
      } else {
        a.download = 'image.png';
      }
      a.click();
    } catch (err) {
      console.error('Failed to download image', err);
      message.error('Failed to download image');
    }
    setDownloadLoading(false);
  }

  if (!url) {
    message.error('Please select a package first');
    window.open('/package-ai-generation', '_self');
    return;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={4}>
          <Box className={style.box}>
            <div className={style.box1}>
              <div className={style.imgBox}>
                <img src={url} crossOrigin="anonymous" />
              </div>
              <Btn
                className={style.btn1}
                onClick={onDownload}
                loading={downloadLoading}
              >
                Download
              </Btn>
            </div>
          </Box>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
