import { App } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useMemo, useState } from 'react';
import { downloadBarData, downloadImg } from '../../../apis/file';
import { getChart } from '../../../apis/serve';
import ColorsLight from '../../../assets/json/colors-light.json';
import Colors from '../../../assets/json/colors.json';
import style from './chart.module.scss';
import ChartContainer from './container.jsx';

const AXIS_LABEL_MAX_LENGTH = 18;

function Chart({
  name,
  tabName,
  tab2Name,
  filter,
  compare,
  hasCompare,
  size,
  title,
  sortList,
  hiddenItems = [],
  appendSaveDataFn,
  chartKey,
  userSelectChart,
  userSelectChartKey,
  userSelectChartValue,
  onUserSelectChart,
}) {
  const app = App.useApp();
  const { message } = app;

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [count, setCount] = useState(0);
  const [compareCount, setCompareCount] = useState(0);
  const [compareData, setCompareData] = useState([]);
  const [needLoad, setNeedLoad] = useState(true);
  const [isIntersecting, setIntersecting] = useState(false);
  const [showDataLabels, setShowDataLabels] = useState(false);

  useEffect(() => {
    setNeedLoad(true);
  }, [filter, compare, hasCompare, userSelectChart]);

  async function read() {
    if (loading) return;
    setLoading(true);
    try {
      const ff = { ...filter };
      if (userSelectChart !== name && userSelectChartKey) {
        ff[userSelectChartKey] = [userSelectChartValue];
      }
      const { data } = await getChart(name, ff);
      if (data) {
        const { data: chartData, total } = data;
        const dd = chartData.filter((e) => !hiddenItems.includes(e[0]));
        setCount(total);
        if (!sortList) {
          const sortedData = dd.sort((a, b) => a[1] - b[1]);
          setFilterData(sortedData);
        } else {
          const sortedData = sortList.map((e) => {
            const fd = dd.find((e1) => e1[0] === e);
            if (!fd) return [e, 0];
            else return fd;
          });
          setFilterData(sortedData);
        }
      } else {
        setFilterData([]);
      }
      if (hasCompare) {
        const cf = { ...compare };
        if (userSelectChart && userSelectChart !== name) {
          cf[userSelectChartKey] = [userSelectChartValue];
        }
        const { data: cd } = await getChart(name, cf);
        if (cd) {
          const { data: chartData, total } = cd;
          setCompareCount(total);
          setCompareData(chartData);
        } else {
          setCompareData([]);
        }
      } else {
        setCompareData([]);
      }
    } catch (err) {
      console.error('read chart data error', name, err);
      message.error('Read chart data error');
    }
    setLoading(false);
    setNeedLoad(false);
  }

  useEffect(() => {
    if (needLoad && isIntersecting) {
      void read();
    }
  }, [needLoad, isIntersecting]);

  const option = useMemo(() => {
    const o = {
      legend: {
        icon: 'circle',
        bottom: '0',
        left: 0,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: 'rgba(255,255,255, 1)',
          // fontSize: '8px',
        },
        selectedMode: false,
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        valueFormatter(value) {
          return `${value.toFixed(2)}%`;
        },
      },
      grid: {
        top: '3%',
        left: '0',
        right: '5%',
        bottom: '0',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          // fontSize: '8px',
          color: '#fff',
          formatter(value) {
            return `${value.toFixed(0)}%`;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#63636369',
            width: 1,
          },
        },
        max: 'dataMax',
      },
      yAxis: {
        type: 'category',
        data: [],
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#fff',
          // fontSize: '8px',
          fontWeight: 'bold',
          /**
           * @param {string} value
           * @returns {string}
           */
          formatter: (value) => {
            if (value.length <= AXIS_LABEL_MAX_LENGTH) return value;

            const lines = [];
            let currentLine = '';

            // 按空格分割单词，并逐个加入当前行，直到超过最大长度
            const words = value.split(' ');
            words.forEach((word) => {
              const afterAdd = `${currentLine} ${word}`.trim();
              if (afterAdd.length <= AXIS_LABEL_MAX_LENGTH) {
                currentLine = afterAdd;
              } else {
                lines.push(currentLine);
                currentLine = word;
              }
            });
            // add last line
            if (currentLine.length > 0) {
              lines.push(currentLine);
            }
            return lines.slice(0, 2).join('\n');
          },
        },
        axisLine: {
          lineStyle: {
            color: '#00A32E',
          },
        },
      },
      color: Colors,
      series: [],
    };
    // set category
    if (hasCompare) {
      const category = filterData.map((item) => item[0]);
      for (const item of compareData) {
        if (!category.includes(item[0])) {
          category.push(item[0]);
        }
      }
      o.yAxis.data = category;
    } else {
      o.yAxis.data = filterData.map((item) => item[0]);
    }
    if (o.yAxis.data.length > 8) {
      o.dataZoom = [
        {
          type: 'slider', // 滑动条型 dataZoom 组件
          show: true, // 显示滚动条
          startValue: o.yAxis.data.length - 8,
          endValue: o.yAxis.data.length,
          brushSelect: false,
          yAxisIndex: 0,
        },
      ];
      o.grid.right = '13%';
    }

    function processUserSelectData(e, i, ci) {
      if (userSelectChart !== name) return e;
      const categories = o.yAxis.data;
      const c = categories[i];
      if (userSelectChartValue === c) return e;
      return {
        value: e,
        itemStyle: {
          color: ColorsLight[ci],
        },
      };
    }

    const serieOption = {
      type: 'bar',
      label: {
        show: showDataLabels,
        formatter: (params) => {
          return params.value.toFixed(2) + '%';
        },
      },
      // itemStyle: {
      //   color: '#00A32E',
      // },
    };
    // set series
    if (hasCompare) {
      const categories = o.yAxis.data;
      o.series = [
        {
          ...serieOption,
          data: categories
            .map((e) => {
              const fd = filterData.find((item) => item[0] === e);
              return ((fd ? fd[1] : 0.0) / count) * 100.0;
            })
            .map((e, i) => processUserSelectData(e, i, 0)),
        },
        {
          ...serieOption,
          data: categories
            .map((e) => {
              const cd = compareData.find((item) => item[0] === e);
              return ((cd ? cd[1] : 0.0) / compareCount) * 100.0;
            })
            .map((e, i) => processUserSelectData(e, i, 1)),
        },
      ];
    } else {
      o.series = [
        {
          ...serieOption,
          data: filterData
            .map((item) => (item[1] / count) * 100.0)
            .map((e, i) => processUserSelectData(e, i, 0)),
        },
      ];
    }
    return o;
  }, [filterData, compareData, showDataLabels, userSelectChart]);

  const [echarts, setEcharts] = useState(null);

  useEffect(() => {
    if (!echarts) return;
    function handleClick(params) {
      const { name: value } = params;
      console.log(
        'chart click',
        userSelectChart,
        name,
        userSelectChartValue,
        value
      );
      if (userSelectChart === name && userSelectChartValue === value) {
        onUserSelectChart('', '', '');
      } else {
        onUserSelectChart(name, chartKey, value);
      }
    }
    echarts.on('click', handleClick);
    return () => {
      echarts.off('click', handleClick);
    };
  }, [echarts, userSelectChart, userSelectChartValue]);

  function initSaveDataFn() {
    if (!appendSaveDataFn) return;
    appendSaveDataFn((wb) => {
      if (!count) return;
      downloadBarData(
        title,
        'xlsx',
        count,
        filterData,
        hasCompare,
        compareCount,
        compareData,
        wb
      );
    });
  }
  useEffect(() => {
    initSaveDataFn();
  }, [appendSaveDataFn]);

  return (
    <ChartContainer
      name={name}
      tabName={tabName}
      tab2Name={tab2Name}
      title={title}
      count={count}
      size={size}
      loading={loading}
      setIntersecting={setIntersecting}
      onSaveImg={() => downloadImg(title, echarts)}
      onSaveExcel={() =>
        downloadBarData(
          title,
          'xlsx',
          count,
          filterData,
          hasCompare,
          compareCount,
          compareData
        )
      }
      onSaveCsv={() =>
        downloadBarData(
          title,
          'csv',
          count,
          filterData,
          hasCompare,
          compareCount,
          compareData
        )
      }
      onDataLabelChange={setShowDataLabels}
    >
      <ReactECharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        className={style.chart}
        onChartReady={setEcharts}
      ></ReactECharts>
    </ChartContainer>
  );
}

export default Chart;
