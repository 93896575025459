import React from 'react';
import { howToUseTool, Filter, arrowUp } from '../../../components/icons/icons.jsx';

export const guideData = {
  data: [
    {
      title: 'Index score',
      content:
        'The Trend Index has been developed to reflect a higher interest in recency and novelty trending twists. The algorithm considers three key elements: (1) volume, (2) long-term and (3) short term growth:',
      subdata: [
        {
          subtitle: 'Volume',
          suncontent:
            ' Considers the number of mentions on TikTok, posts on Meta and searches on Google. It allows us to differentiate between a "big bet" and a "small bet"',
        },
        {
          subtitle: 'Long-term growth',
          suncontent:
            'Considers how the trending twist has grown/declined over a longer period of time.',
        },
        {
          subtitle: 'Short-term growth',
          suncontent:
            'Considers how the trend has grown/declined in the last month. Has a greater influence than long-term growth to emphasize new rising trends.',
        },
      ],
      footercontent:
        'As volume is different across markets, the trend index score is normalized within the market (and thus cannot be compared across markets). To enable users to compare index scores across different dishes, we have normalized the score across markets and brands.',
      icon: (
        <div className='guide-data-icon'>
          <span>Index <br/>Score</span>
          <span><img
            src={Filter}
            alt="filter"
          /></span>
        </div>
      ),
    },
    {
      title: 'Trend lifestage',
      content:
        'There are four types of trend lifestages for the trending twists:',
      subdata: [
        {
          subtitle: 'Seasonal Trend:',
          suncontent:
            'The data shows a seasonal pattern with recurring fluctuations that follow a specific cycle.',
        },
        {
          subtitle: 'Fluctuating trend:',
          suncontent:
            'The data shows non-seasonal fluctuations with irregular variations that do not follow a specific cycle.',
        },
        {
          subtitle: 'Consistent Decrease Trend:',
          suncontent:
            'The data shows a consistent decrease over time.',
        },
        {
          subtitle: 'Consistent Increase Trend:',
          suncontent:
            'The data shows a consistent increase over time.',
        },
      ],
      // Removed icon and replaced with name in div
      icon: (
        <div className='guide-data-icon'>
          <span>Trend <br/>Lifecycle</span>
          <span><img
            src={Filter}
            alt="filter"
          /></span>
        </div>
      ),
    },
    {
      title: 'Category relevance',
      content:
        'The relevance score shows how relevant the trending twist is to our Top Dish Category. This score is on a scale from 0 to 100.\n\nScores as high as 100 indicate the highest relevance of a trending twist to its top dish category and scores as low as 0 indicate the lowest relevance score of a trending twist to its top dish category. \n\nSearch count data is used to determine how relevant a trending twist is relative to others in its Top Dish category and can be understood using the following calculation:\n\nRelevance Score = (Search Count of Trending Twist - Mean Search Count) ÷ Standard Deviation of Search Counts.\n\nThe values are further normalised so that the lowest score will equal 0 and the highest 100.',
      subdata: [],
      icon: (
        <div className='guide-data-icon'>
          <span>Category <br/>Relevance</span>
          <span><img
            src={arrowUp}
            alt="arrowUp"
          /></span>
        </div>
      ),
    },
  ],
  faq: [
    {
      question: 'What is the most important trending twist for me to focus on in my market?',
      answer: 'You can filter the dishes that are relevant for your market using the filters at the top of the dashboard. As the trend index score is determined across dishes, the trending twist with the highest trend index score is the most trending for your market.',
    },
    {
      question: 'If my brand\'s primary top dish is pasta, what trending twist should I focus on?',
      answer: 'You can filter for your brand and top dish using the filters at the top of the dashboard. The trending twist for pasta with the highest trend index score will be the most trending.',
    },
    {
      question: 'Why are there no \'Trending Twists\' with a high index score for my key top dish?',
      answer: 'The trend index score is calculated across dishes to determine the most trending twists across all dishes. This means that if there is a viral trending twist for a particular top dish (e.g. soup) that has grown significantly in the last month, the growth in trending twists for another top dish (e.g. pasta) may be relatively low and thus receive a lower index score. In simple terms, there are other top dishes trending more than the one you have chosen - it is recommended to look at the other top dish options before proceeding to execution',
    },
    {
      question: 'What is the most trending twist on TikTok?',
      answer: 'If you are looking for the most trending twist on a particular channel (e.g. TikTok), you can use the filters to sort the columns based on the growth for that channel. For the most trending twist on TikTok, look for the twist that has shown the highest growth in volume in the last month.',
    },
    {
      question: 'How often are the trending twists updated?',
      answer: 'The trending twists are updated every month. The top of the dashboard will show the date on which the data was refreshed.',
    },
    {
      question: 'Why is the trend index not available in my market yet?',
      answer: 'We are developing the trend index for markets on a rolling basis. Currently, the index is available for the US and India. Brazil and Indonesia are planned for launch in Q4 2024.',
    },
    {
      question: 'Why are there no Meta Posts and/or TikTok Videos for particular trending twists? ',
      answer: 'Where available, content for your selected trending twist will be visible. However, it is important to note that not all trending twists will have Meta posts and/or TikTok Videos.',
    },
    {
      question: 'Why are some of the characters in the hashtags of posts appearing as question marks?',
      answer: 'This is a result of emojis and unrecognizable characters.',
    },
    {
      question: 'Why are some posts appearing from influencers not based in the specified market?',
      answer: 'Due to Brandwatch and Meta limitations, some posts will appear related to the selected trending twist and market, but not always from an influencer based in that specific market.',
    },
    // Add more FAQ items as needed
  ],
  tool:{
    title: 'How to use tool',
    icon: `${howToUseTool}`,
  }
};

