// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._m1eavOVWUAx2f6rYms0{position:relative;width:100vw;height:100vh;overflow:hidden;background-repeat:no-repeat;background-size:cover}.yziJW8bYfyuXGAFuhqVH{position:absolute;top:28%;width:100%;height:37vh;padding:0 15vw;display:flex;justify-content:space-between;align-items:center}.ZhSzB1GlW9aK_B0Zd6z2{position:absolute;top:0;left:0;width:100%;height:100%}.ZhSzB1GlW9aK_B0Zd6z2 img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover}.d6nEcuJfG5Ha7418Z_vu{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.8)}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/views/home/home.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CACA,2BAAA,CACA,qBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,0BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAIJ,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA","sourcesContent":[".homeContainer {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.homeBallLine {\n  position: absolute;\n  top: 28%;\n  width: 100%;\n  height: 37vh;\n  padding: 0 15vw;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.homeBgBox {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.homeBgItem {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: #000000cc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeContainer": `_m1eavOVWUAx2f6rYms0`,
	"homeBallLine": `yziJW8bYfyuXGAFuhqVH`,
	"homeBgBox": `ZhSzB1GlW9aK_B0Zd6z2`,
	"homeBgItem": `d6nEcuJfG5Ha7418Z_vu`
};
export default ___CSS_LOADER_EXPORT___;
