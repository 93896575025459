import React from 'react';
import style from './item.module.scss';

function PackageAiItem({ data, selected, onSelect }) {
  return (
    <div className={style.box} onClick={onSelect}>
      <div className={style.boxInner}>
        <div className={style.img}>
          <img src={data.url} loading="lazy" crossOrigin="anonymous" />
        </div>
        <div className={style.body}>
          <h3>{data.title}</h3>
        </div>
      </div>
      <div className={`${style.cornerBox} ${selected ? style.active : ''}`}>
        <div className={style.corner1} />
        <div className={style.corner2} />
        <div className={style.corner3} />
        <div className={style.corner4} />
      </div>
    </div>
  );
}

export default PackageAiItem;
