import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignOutMessage from '../components/profile/logout-page/logout-page.jsx';
import Login from '../login/login.jsx';
import AiTopDish from '../modules/ai-top-dish/ai-top-dish.jsx';
import BUMonthlyFeedback from '../modules/bu-monthly-feedback/index.jsx';
import BusinessCaseAssitant from '../modules/business-case-assistant/business-case-assistant.jsx';
import ConceptAi from '../modules/concept-ai/concept-ai.jsx';
import ConceptGptView from '../modules/concept-gpt/index.jsx';
import ConsumerTheaterAdmin from '../modules/consumer-theater/admin/consumer-theater-admin.jsx';
import ConsumerTheater from '../modules/consumer-theater/consumer-theater.jsx';
import GotflWeenaPage from '../modules/gotfl-weena/index.jsx';
import Learnings from '../modules/gotfl/pages/learnings/learnings.jsx';
import Home from '../modules/home/home.jsx';
import Navbar from '../modules/home/navbar/navbar.jsx';
import InnoflexGptView from '../modules/innoflex-gpt/index.jsx';
import KnorrList from '../modules/knorr/views/list.jsx';
import KnowledgeHubTree from '../modules/knowledge-hub-tree/knowledge-hub-tree.jsx';
import Sustainability from '../modules/knowledge-hub/sustainability/main.jsx';
import OccasionDashboard from '../modules/occasion-module/views/dashboard/index.jsx';
import OccasionHome from '../modules/occasion-module/views/home/index.jsx';
import OccasionPhotoCompare from '../modules/occasion-module/views/photo/compare.jsx';
import OccasionPhoto from '../modules/occasion-module/views/photo/index.jsx';
import PackageAiGeneration from '../modules/package-ai-generation/views/base/index.jsx';
import PackageAiGenerationEdit from '../modules/package-ai-generation/views/edit/index.jsx';
import PackageAiGenerationRender from '../modules/package-ai-generation/views/render/index.jsx';
import PackageAiGenerationUpdate from '../modules/package-ai-generation/views/update/index.jsx';
import PackageAiInspirationHelp from '../modules/package-ai-inspiration/views/help/help.jsx';
import PackageAiInspiration from '../modules/package-ai-inspiration/views/index.jsx';
import PackageAiPerformanceBrandDrillDown from '../modules/package-ai-performance/views/tree/index.jsx';
import PackageAiPerformanceCompetitiveLandscape from '../modules/package-ai-performance/views/competitive/index.jsx';
import PackageAiPerformance from '../modules/package-ai-performance/views/index.jsx';
import PackageAiScreeningBatchAoi from '../modules/package-ai-screening-batch/views/aoi/index.jsx';
import PackageAiScreeningBatchBasic from '../modules/package-ai-screening-batch/views/basic/index.jsx';
import PackageAiScreeningBatchResult from '../modules/package-ai-screening-batch/views/result/index.jsx';
import PackageAiScreeningBatchWait from '../modules/package-ai-screening-batch/views/wait/index.jsx';
import PackageAiScreeningAoi from '../modules/package-ai-screening/views/aoi/index.jsx';
import PackageAiScreeningAsset from '../modules/package-ai-screening/views/asset/index.jsx';
import PackageAiScreeningBasic from '../modules/package-ai-screening/views/basic/index.jsx';
import PackageAiScreeningDatabase from '../modules/package-ai-screening/views/database/index.jsx';
import PackageAiScreeningDeepDive from '../modules/package-ai-screening/views/deep-dive/index.jsx';
import PackageAiScreeningResult from '../modules/package-ai-screening/views/result/index.jsx';
import PackageAiScreeningShare from '../modules/package-ai-screening/views/share/index.jsx';
import PackageAiScreeningTemplate from '../modules/package-ai-screening/views/template/index.jsx';
import PackageAiScreeningWait from '../modules/package-ai-screening/views/wait/index.jsx';
import PackageAiView from '../modules/package-ai/index.jsx';
import PainPoints from '../modules/pain-points/index.jsx';
import PlantBasedPage from '../modules/plant-based/index.jsx';
import RatingsReview from '../modules/ratings-review/ratings-review.jsx';
import RequestAccessValueDecompositionView from '../modules/request-access-value-decomposition/index.jsx';
import TopDish from '../modules/top-dish/index.jsx';
import TrendsDial from '../modules/trends-dial/trends-dial.jsx';
import MarketTrend from '../modules/trends/components/market-trend/market-trend.jsx';
import ConsumerBarChart from '../modules/trends/pages/barchart-trends.jsx';
import ConsumerTrendTikTikResource from '../modules/trends/pages/consumer-trend-tik-tik-resource.jsx';
import ConsumerTrendVideo from '../modules/trends/pages/consumer-trend-video-play.jsx';
import DrillDownTable from '../modules/trends/pages/drill-down-table.jsx';
import EmergingFoodSolution from '../modules/trends/pages/emerging-food-solution.jsx';
import FeelGood from '../modules/trends/pages/feel-good.jsx';
import Flavour from '../modules/trends/pages/flavour.jsx';
import Joyful from '../modules/trends/pages/joyful.jsx';
import LowWaste from '../modules/trends/pages/low-waste.jsx';
import MarTrends from '../modules/trends/pages/market-trend-page.jsx';
import MarketTrendSearchTikTok from '../modules/trends/pages/market-trend-search-tik-tok.jsx';
import Mindful from '../modules/trends/pages/mindful.jsx';
import ModernizedComfort from '../modules/trends/pages/modernized-comfort.jsx';
import PopulationSegregation from '../modules/trends/pages/population-segregation.jsx';
import ConsumerTrendsInnovation from '../modules/trends/pages/trends-innovation-page.jsx';
import Wild from '../modules/trends/pages/wild.jsx';
import TT10View from '../modules/tt10/index.jsx';
import UserManagementPage from '../modules/user-management/pages/user-management-page.jsx';
import ValueDecompositionView from '../modules/value-decomposition/index.jsx';
import VistaView from '../modules/vista/index.jsx';

function NavRoutes({ userName }) {
  return (
    <Routes>
      <Route path="/" element={<Home userName={userName} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/navbar" element={<Navbar />} />
      <Route
        path="/business-case-assistant"
        element={<BusinessCaseAssitant />}
      />
      <Route exact path="/conceptai" element={<ConceptAi />} />
      <Route path="/frontlinetime" element={<Learnings userName={userName} />}>
        <Route path=":id?" element={<Learnings userName={userName} />}></Route>
      </Route>
      <Route
        exact
        path="/commercializing-sustainability"
        element={<Sustainability />}
      />
      <Route exact path="/trends-dial" element={<TrendsDial />}></Route>
      <Route
        exact
        path="/market-trend-performance"
        element={<MarTrends />}
      ></Route>
      <Route
        exact
        path="/market-trend-search-tik-tok"
        element={<MarketTrendSearchTikTok />}
      ></Route>
      <Route exact path="/trends-category" element={<ConsumerBarChart />} />
      <Route exact path="/market-trend" element={<MarketTrend />} />
      <Route
        exact
        path="/consumer-trend"
        element={<ConsumerTrendTikTikResource />}
      ></Route>
      <Route
        exact
        path="/consumer-trends-innovations"
        element={<ConsumerTrendsInnovation />}
      ></Route>
      <Route
        exact
        path="/consumer-trend-video"
        element={<ConsumerTrendVideo />}
      ></Route>
      <Route
        exact
        path="/population-segregation"
        element={<PopulationSegregation />}
      ></Route>
      <Route
        exact
        path="/consumer-barchart"
        element={<ConsumerBarChart />}
      ></Route>
      <Route
        exact
        path="/drill-down-table"
        element={<DrillDownTable />}
      ></Route>
      <Route path="/consumertheater" element={<ConsumerTheater />}>
        <Route path=":id?" element={<ConsumerTheater />}></Route>
      </Route>
      <Route
        path="/consumertheater/admin/:pageType"
        element={<ConsumerTheaterAdmin />}
      ></Route>
      <Route
        exact
        path="/emerging-food"
        element={<EmergingFoodSolution />}
      ></Route>
      <Route exact path="/feel-good-food" element={<FeelGood />}></Route>
      <Route
        exact
        path="/modernized-comfort-food"
        element={<ModernizedComfort />}
      ></Route>
      <Route exact path="/low-waste-menus" element={<LowWaste />}></Route>
      <Route exact path="/wild-pure" element={<Wild />}></Route>
      <Route exact path="/joyful-sharing" element={<Joyful />}></Route>
      <Route exact path="/flavour-contrasts" element={<Flavour />}></Route>
      <Route exact path="/mindful-proteins" element={<Mindful />}></Route>
      <Route
        exact
        path="/knowledge-tree"
        element={<KnowledgeHubTree />}
      ></Route>
      <Route exact path="/top-dish" element={<TopDish />}></Route>
      <Route exact path="/plantbased" element={<PlantBasedPage />}></Route>
      <Route exact path="/bu-feedback" element={<BUMonthlyFeedback />}></Route>
      <Route exact path="/occasion" element={<OccasionHome />}></Route>
      <Route exact path="/occasion-photo" element={<OccasionPhoto />}></Route>
      <Route
        exact
        path="/occasion-photo-compare"
        element={<OccasionPhotoCompare />}
      ></Route>
      <Route
        exact
        path="/occasion-dashboard"
        element={<OccasionDashboard />}
      ></Route>
      <Route exact path="/gotflweena" element={<GotflWeenaPage />}></Route>

      <Route exact path="/knorr" element={<KnorrList />}>
        <Route path=":id?" element={<KnorrList />}></Route>
      </Route>

      <Route exact path="/vista" element={<VistaView />}></Route>
      <Route
        exact
        path="/value-decomposition"
        element={<ValueDecompositionView />}
      ></Route>
      <Route exact path="/ratings-review" element={<RatingsReview />}></Route>
      <Route
        exact
        path="/request-access-value-decomposition"
        element={<RequestAccessValueDecompositionView />}
      ></Route>
      <Route exact path="/tt10" element={<TT10View />}></Route>

      <Route exact path="/concept-gpt" element={<ConceptGptView />}></Route>
      <Route exact path="/innoflex-gpt" element={<InnoflexGptView />}></Route>

      <Route exact path="/package-ai" element={<PackageAiView />}></Route>

      <Route
        path="/package-ai-performance"
        element={<PackageAiPerformance />}
      ></Route>
      <Route
        path="/package-ai-performance/competitive"
        element={<PackageAiPerformanceCompetitiveLandscape />}
      ></Route>
      <Route
        path="/package-ai-performance/brand"
        element={<PackageAiPerformanceBrandDrillDown />}
      ></Route>

      <Route
        path="/package-ai-inspiration"
        element={<PackageAiInspiration />}
      ></Route>

      <Route
        path="/package-ai-inspiration/help"
        element={<PackageAiInspirationHelp />}
      ></Route>

      <Route
        exact
        path="/package-ai-generation"
        element={<PackageAiGeneration />}
      ></Route>
      <Route
        exact
        path="/package-ai-generation2"
        element={<PackageAiGenerationEdit />}
      ></Route>
      <Route
        exact
        path="/package-ai-generation3"
        element={<PackageAiGenerationUpdate />}
      ></Route>
      <Route
        exact
        path="/package-ai-generation4"
        element={<PackageAiGenerationRender />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening"
        element={<PackageAiScreeningBasic />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-basic"
        element={<PackageAiScreeningBasic />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-database"
        element={<PackageAiScreeningDatabase />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-template"
        element={<PackageAiScreeningTemplate />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-asset"
        element={<PackageAiScreeningAsset />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-aoi"
        element={<PackageAiScreeningAoi />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-wait"
        element={<PackageAiScreeningWait />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-result"
        element={<PackageAiScreeningResult />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-deep-dive"
        element={<PackageAiScreeningDeepDive />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-share"
        element={<PackageAiScreeningShare />}
      ></Route>

      <Route exact path="/painpoints" element={<PainPoints />}></Route>

      <Route
        exact
        path="/package-ai-screening-batch-basic"
        element={<PackageAiScreeningBatchBasic />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-batch-aoi"
        element={<PackageAiScreeningBatchAoi />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-batch-wait"
        element={<PackageAiScreeningBatchWait />}
      ></Route>
      <Route
        exact
        path="/package-ai-screening-batch-result"
        element={<PackageAiScreeningBatchResult />}
      ></Route>

      <Route
        exact
        path="/user-management"
        element={<UserManagementPage />}
      ></Route>
      <Route exact path="/logout" element={<SignOutMessage />}>
        {' '}
      </Route>

      <Route exact path="/ai-top-dish" element={<AiTopDish />}></Route> 
    </Routes>
  );
}

export default NavRoutes;
