import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import closeSvg from '../../../../assets/images/gotfl-weena/close-icon.svg';

import { Modal, Spin, message } from 'antd';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import Dragger from 'antd/es/upload/Dragger.js';

import uploadTag from '../../../../assets/images/gotfl-weena/upload_tag.svg';
import IntroCom from './introCom.jsx';
import MediumCom from './mediumCom.jsx';
import DocumentCom from './documentCom.jsx';
import { useRoot } from '../../hooks/useRoot.jsx';
import { fileUpload, postResource, postWorkshop, putResource, putWorkshop } from '../../api/request.js';
import { LoadingOutlined } from '@ant-design/icons';
import { isWinDpr1 } from '../../../../util/utils.jsx';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 22,
      color: '#78F550',
    }}
    spin
  />
);

const IntroUpdatePopup = ({onRefresh = () => {}}) => {
  const { openEditPopup, editType, isEdit, infoDetail, shopId, updateRefreshFlag, fnOpenEditPopup } = useRoot();
  const [ formData, setFormData ] = useState({});
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState('');
  const [file, setFile] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [initData, setInitData] = useState({});

  // clean info
  useEffect(() => {
    if(!openEditPopup) {
      setInitData({})
      setPreviewUrl('')
      setFile('')
      setThumb('')
      setFormData({})
    }
  }, [openEditPopup])

  useEffect(() => {
    if(openEditPopup && isEdit) {
      const tempInfoDetail = {...infoDetail};
      if(editType === 'intro') {
        // other format
      } else {
        let format = '';
        const fileNameList = tempInfoDetail.name.split('.');
        if (fileNameList[fileNameList.length - 1].indexOf('?') === -1) {
          format = fileNameList[fileNameList.length - 1];
        } else {
          format = fileNameList[fileNameList.length - 1].split('?')[0];
        }
        tempInfoDetail['format'] = format;
        setFile(tempInfoDetail.file)
      }

      setThumb(tempInfoDetail.thumb)
      setInitData(tempInfoDetail);
      setPreviewUrl(tempInfoDetail.thumbUrl);
    }
  }, [openEditPopup, isEdit, infoDetail, editType]);

  const fnFormChagne = (info) => {
    setFormData(info);
  };

  const getFileExt = (file) => {
    if (!file) {
      console.error('The file parameter is empty');
      return '';
    }
    const fileName = file.name || file;
    const fileNameList = fileName.split('.');
    if (fileNameList[fileNameList.length - 1].indexOf('?') === -1) {
      return fileNameList[fileNameList.length - 1];
    }
    return fileNameList[fileNameList.length - 1].split('?')[0];
  };

  const beforeUpload = (file) => {
    const suffix = getFileExt(file);
    let allowSuffixList = [];
    if(['intro', 'picture', 'document'].includes(editType)) {
      allowSuffixList = ['jpeg', 'jpg', 'jpe', 'png'];
    } else {
      allowSuffixList = ['mp4', 'flv', 'avi', 'mov', 'wmv'];
    }
    const validate = allowSuffixList.includes(
      suffix.toLowerCase()
    );
    if (!validate) {
      message.error('You can only upload JPG/PNG/MP4/MOV/FLV/AVI file!');
      return;
    }
    const sizeForM = file.size / 1024 / 1024;

    setLoading(true);
    const formData = new FormData();
    formData.append('prefix', 'gotflweena');
    formData.append('file', file);
    fileUpload(formData)
      .then((res) => {
        const {file_key, key, url, video_cover_file=''} = res.data;
        
        setFile(key);
        if(video_cover_file) {
          const urlList = url.split('?');
          const argList = urlList[1].split('&');
          const tempUrl = `${urlList[0]}?file_key=${video_cover_file}&${argList[1]}`;

          setThumb(video_cover_file);
          setPreviewUrl(tempUrl);
        } else {
          setThumb(key);
          setPreviewUrl(url);
        }

        if(['picture', 'document', 'video'].includes(editType)) {
          const tempObj = Object.assign({}, initData, {
            name: file_key,
            size: sizeForM,
            format: suffix,
          })
          setInitData(tempObj);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  // add new workshop or update old workshop
  const fnAddOrEditWorkshop = useCallback(async () => {
    try {
      const tempObj = {
        ...formData,
        extra: '',
        thumb: thumb,
      };
      if(!tempObj.thumb) {
        message.error('Cover picture is required');
        return;
      }
      if(!tempObj.name) {
        message.error('The name parameter is required');
        return;
      }
      let result = null;
      if(isEdit) {
        // edit
        result = await putWorkshop(tempObj);
      } else {
        // add new 
        result = await postWorkshop(tempObj);
      }
      if(result && result.status === 'Success') {
        // refresh list and close popup
        onRefresh()
        fnOpenEditPopup(false)
      }
    } catch(error) {
      console.log(error);
    }
  }, [thumb, isEdit, formData]);

  // add new workshop resource or update old workshop resource
  const fnAddOrEditResource = useCallback(async () => {
    try {
      const tempObj = {
        shopId,
        url: '',
        ...formData,
        thumb: thumb,
        type: editType,
        extra: '',
        file,
      };
      if(!tempObj.thumb) {
        message.error('Cover picture is required');
        return;
      }
      if(!tempObj.name) {
        message.error('The name parameter is required');
        return;
      } else {
        const susuffix = `.${tempObj.format}`
        if(!tempObj.name.includes(susuffix)) {
          tempObj.name = `${tempObj.name}${susuffix}`
        }
      }

      let result = null;
      if(isEdit) {
        // edit
        result = await putResource(tempObj);
      } else {
        // add new 
        result = await postResource(tempObj);
      }
      if(result && result.status === 'Success') {
        // refresh list and close popup
        updateRefreshFlag(new Date().getTime())
        fnOpenEditPopup(false)
      }
    } catch(error) {
      console.log(error);
    }
  }, [thumb, editType, shopId, file, formData, isEdit]);

  // confirm button
  const fnConfirm = useCallback(() => {
    if(editType === 'intro') {
      fnAddOrEditWorkshop();
    } else {
      fnAddOrEditResource();
    }
  }, [fnAddOrEditWorkshop, editType, fnAddOrEditResource]);

  return <Modal open={openEditPopup} closeIcon={false} width="1000px" centered={isWinDpr1()} footer={false} className={style.customModalWrap}  destroyOnClose={true}>
    <img src={closeSvg} className={classNames(style.closeIcon)} onClick={() => fnOpenEditPopup(false)} />
    <BorderContainer id="intro_1">
      <div className={classNames(style.contentWrap)}>
        <div className={classNames(style.formBox)}>
          <div className={classNames(style.leftWrap)}>
            <div className={classNames(style.label)}>{editType === 'video' ? 'Video' : 'Picture'} :</div>
            <Dragger
              className={classNames(style.uploadBox)}
              name="file"
              accept={['.ppt', '.pptx', 'image/*', 'video/*']}
              showUploadList={false}
              action=""
              beforeUpload={beforeUpload}
              disabled={loading}
            >
              {
                loading ? <Spin
                  wrapperClassName={style.updateImageLoading}
                  indicator={antIcon}
                  spinning={loading}></Spin> : (!previewUrl ? <div className={classNames(style.uploadFlag)}>
                  <img src={uploadTag}></img>
                  <span>Upload or drag here</span>
                </div> : <img src={previewUrl} className={classNames(style.img)}></img>)
              }
            </Dragger>
          </div>
          {
            editType === 'intro' && <IntroCom initData={initData} onValueChange={fnFormChagne}></IntroCom>
          }
          {
            ['picture', 'video'].includes(editType) && <MediumCom initData={initData} onValueChange={fnFormChagne}></MediumCom>
          }
          {
            editType === 'document' && <DocumentCom initData={initData} onValueChange={fnFormChagne}></DocumentCom>
          }
        </div>
        <div className={classNames(style.bntBox)}>
          <span className={classNames(style.btn)} onClick={fnConfirm}>Confirm</span>
        </div>
      </div>
    </BorderContainer>
  </Modal>;
};

export default IntroUpdatePopup;