import { ColorPicker, Input, message, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import style from './text.module.scss';

const FONT_SIZE_OPTIONS = [
  { label: '8px', value: 8 },
  { label: '16px', value: 16 },
  { label: '24px', value: 24 },
  { label: '48px', value: 48 },
  { label: '64px', value: 64 },
  { label: '72px', value: 72 },
];

const TEXT_ALIGN_OPTIONS = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
];

function FormLabel({ children }) {
  return (
    <div className={style.formLabel}>
      <div className={style.formIcon} />
      <div className={style.formLabelContent}>{children}</div>
    </div>
  );
}

function PackageAiText({ className, data, scale = 1, addText, updateText }) {
  const selectedIndex = useMemo(() => {
    return data.findIndex((e) => e.selected && e.type === 'text');
  }, [data]);
  const selectedItem = useMemo(() => {
    if (selectedIndex >= 0) {
      return data[selectedIndex];
    }
    return null;
  }, [data, selectedIndex]);

  const [size, setSize] = useState(16);
  const [font, setFont] = useState('unilever-shilling-regular');
  const [color, setColor] = useState('#00a32e');
  const [textAlign, setTextAlign] = useState('left');
  const [text, setText] = useState();

  const onColorChange = useCallback(
    (color, hex) => {
      console.log('color', color, hex);
      setColor(hex);
    },
    [setColor]
  );

  const fontSizeOptions = useMemo(() => {
    return FONT_SIZE_OPTIONS.map((e) => {
      return {
        label: e.label,
        value: Math.round(e.value / scale),
      };
    });
  }, [scale]);
  useEffect(() => {
    setSize(fontSizeOptions[1].value);
  }, [fontSizeOptions]);

  useEffect(() => {
    if (selectedItem) {
      setSize(selectedItem.fontSize);
      setFont(selectedItem.fontFamily);
      setColor(selectedItem.color);
      setTextAlign(selectedItem.textAlign);
      setText(selectedItem.text);
    } else {
      setSize(fontSizeOptions[1].value);
      setFont('unilever-shilling-regular');
      setColor('#00a32e');
      setTextAlign('left');
      setText();
    }
  }, [data, selectedItem]);

  const onSubmit = useCallback(() => {
    if (!text) {
      message.warning('Please enter text');
      return;
    }
    if (selectedItem) {
      updateText(selectedIndex, {
        ...selectedItem,
        fontSize: size,
        fontFamily: font,
        textAlign: textAlign,
        color: color,
        text,
      });
    } else {
      addText({
        fontSize: size,
        fontFamily: font,
        textAlign: textAlign,
        color: color,
        text,
      });
    }
  }, [text, size, font, color, textAlign, selectedItem]);

  return (
    <div className={`${style.textBox} ${className}`}>
      <FormLabel>Font</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={font}
        onChange={setFont}
        options={[
          {
            label: 'Unilever Shilling Regular',
            value: 'unilever-shilling-regular',
          },
        ]}
      />
      <FormLabel>Color</FormLabel>
      <ColorPicker
        style={{ width: '100%' }}
        value={color}
        onChange={onColorChange}
        defaultFormat="hex"
        showText={true}
      />
      <FormLabel>Size</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={size}
        onChange={setSize}
        options={fontSizeOptions}
      />
      <FormLabel>Align</FormLabel>
      <Select
        style={{ width: '100%' }}
        value={textAlign}
        onChange={setTextAlign}
        options={TEXT_ALIGN_OPTIONS}
      />
      <FormLabel>Content</FormLabel>
      <Input.TextArea
        style={{ marginTop: '10px' }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Please enter text"
        autoSize={{ minRows: 2, maxRows: 6 }}
      />
      <div className={style.textBtn}>
        <Btn onClick={onSubmit}>{selectedItem ? 'Update' : 'Submit'}</Btn>
      </div>
    </div>
  );
}

export default PackageAiText;
