import React, { useEffect, useState } from 'react';
import { aiTopDishEngine } from '../../../../components/icons/icons.jsx';
import './sticky-header.scss';

const AiTopDishHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('AiTpDishHeader');
      const sticky = header.offsetTop;
      setIsSticky(window.pageYOffset > sticky);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <div className={`ml-40 ${isSticky ? 'aiTopDishSticky' : ''}`} id="AiTpDishHeader">
        <div className='mb-30 aiTpDishCommonHeader'>
        <div className='aiTpDishHeaderTitle aiTpDishBigTitle'>
          Top Dish Trend Index
        </div>
        <div>
          <img src={aiTopDishEngine} alt="info" />
        </div>
      </div>
      </div>
  );
};

export default AiTopDishHeader;
