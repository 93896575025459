// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kJEYZOTmubxAVfxW574E{padding:15px}.DZZhmXTCT0O4WF6zOVQX{margin-top:3px;display:flex;align-items:center}.HLnTaTaj9kOtziPUaZP7{height:24px;margin-right:5px}.PH7b7S0LII3OTRFyEvvo{color:#00a32e;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-screening/components/legend2.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CAGF,sBACE,aAAA,CACA,cAAA","sourcesContent":[".boxInner {\n  padding: 15px;\n}\n\n.box11 {\n  margin-top: 3px;\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  height: 24px;\n  margin-right: 5px;\n}\n\n.text2 {\n  color: #00a32e;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxInner": `kJEYZOTmubxAVfxW574E`,
	"box11": `DZZhmXTCT0O4WF6zOVQX`,
	"icon": `HLnTaTaj9kOtziPUaZP7`,
	"text2": `PH7b7S0LII3OTRFyEvvo`
};
export default ___CSS_LOADER_EXPORT___;
