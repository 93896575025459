import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import Box from '../../components/box/index.jsx';
import Compare from '../../components/compare/compare.jsx';
import { ModalContext } from '../../components/context/context.jsx';
import ModalGreen from '../../components/modal/modal.jsx';
import Perceived from '../../components/perceived/perceived.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import Topline from '../../components/topline/topline.jsx';
import style from './main.module.scss';

const PackageAiView = () => {
  // tab list
  const tabList = [
    { title: 'Topline Metrics' },
    { title: 'Perceived Benefits Deepedive' },
    { title: 'Compare' },
  ];
  const [modalType, setModalType] = useState(0);

  // tab active
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (index) => {
    setActiveTab(index);
    setModalType(index);
  };

  // Modal
  const { isOpen, modalData, closeModal } = useContext(ModalContext);
  const [newModalData, setNewModalData] = useState({});

  // build modal data
  const buildModalData = (type) => {
    let data = [];
    if (type === 0) {
      data = [
        {
          title: 'Brand Logo Visibility',
          score: modalData.brand_logo_visibility,
        },
        {
          title: 'Variant Name Visibility',
          score: modalData.variant_name_visibility,
        },
        {
          title: 'Appeal',
          score: modalData.appeal,
        },
        {
          title: 'Unpriced Purchase Intent',
          score: modalData.upi,
        },
      ];
    } else if (type === 1) {
      data = [
        {
          title: 'Taste',
          score: modalData.taste,
        },
        {
          title: 'Premium',
          score: modalData.premium,
        },
        {
          title: 'Natural',
          score: modalData.natural,
        },
        {
          title: 'Value',
          score: modalData.value,
        },
        {
          title: 'Sustainable',
          score: modalData.sustainable,
        },
        {
          title: 'Modernity',
          score: modalData.modernity,
        },
        {
          title: 'Excitement',
          score: modalData.excitement,
        },
        {
          title: 'Desirability',
          score: modalData.desirability,
        },
      ];
    }

    // const sortedData = [...data].sort((a, b) => a.strength_of_assocition - b.strength_of_assocition);

    const markFlags = data.map((item) => {
      let flag;
      if (Number(item.score) > 70) {
        flag = 'ok';
      }
      if (Number(item.score) >= 41 && Number(item.score) <= 70) {
        flag = 'bad';
      }
      if (Number(item.score) < 41) {
        flag = 'worse';
      }
      return {
        title: item.title,
        flag,
      };
    });

    const updatedData = data.map((item) => {
      const mark = markFlags.find((mark) => mark.title === item.title);
      return {
        ...item,
        flag: mark.flag,
      };
    });

    const obj = {
      title: modalData.name,
      id: modalData.sn,
      market: modalData.market,
      table: {
        title: 'Metric',
        barContent: 'Score',
        body: updatedData,
      },
    };
    return obj;
  };

  useEffect(() => {
    if (modalData && Object.keys(modalData).length > 0) {
      const obj = buildModalData(modalType);
      setNewModalData(obj);
    }
  }, [modalData]);

  return (
    <div className={style.fixedContent}>
      <TopBar />
      <Box className={style.contentWrap}>
        <div className={style.contentInner}>
          <div className={style.tabBar}>
            {tabList.map((tab, index) => {
              return (
                <div
                  className={classNames(
                    index === 0 ? style.firstTab : '',
                    index === 1 ? style.secondTab : '',
                    index === 2 ? style.thirdTab : '',
                    index === activeTab ? style.active : ''
                  )}
                  onClick={() => {
                    toggleTab(index);
                  }}
                  key={index}
                >
                  <div className={style.outerCircle}>
                    <div className={style.innerCircle}></div>
                  </div>
                  {index === 1 && <div className={style.lineRight}></div>}
                  {index === 1 && <div className={style.lineLeft}></div>}
                  <span className={style.navTitle}>{tab.title}</span>
                </div>
              );
            })}
          </div>
          <div className={style.tabContent}>
            {activeTab === 0 && <Topline />}
            {activeTab === 1 && <Perceived />}
            {activeTab === 2 && <Compare />}
          </div>
        </div>
      </Box>
      {/* Modal */}
      <ModalGreen
        open={isOpen}
        data={newModalData}
        modalType={modalType}
        closeModal={closeModal}
      />
    </div>
  );
};

export default PackageAiView;
