import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import { Input, Radio } from 'antd';
const MediumCom = ({initData, onValueChange = () => {}}) => {
  const [showType, setShowType] = useState('main')
  const [formObj, setFormObj] = useState({});

  useEffect(() => {
    if(Object.keys(initData).length) {
      setFormObj(initData);
    }
  }, [initData]);

  useEffect(() => {
    formObj['showType'] = showType
    onValueChange(formObj);
  }, [formObj]);

  const fnValChange = (e, field) => {
    const val = e.target.value;
    const tempFormObj = {...formObj};
    tempFormObj[field] = val;
    setFormObj(tempFormObj);
  };

  return <div className={classNames(style.rightWrap)}>
    <div className={classNames(style.ctrlBox)} style={{display: 'flex'}}>
      <div className={classNames(style.label)} style={{marginRight: '30px'}}>Type:</div>
      <Radio.Group value={showType} onChange={(e) => setShowType(e.target.value)}>
        <Radio value="main">Main</Radio>
        <Radio value="other">Other</Radio>
      </Radio.Group>
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Name:</div>
      <Input placeholder="Enter an name" value={formObj?.name} onChange={(e) => fnValChange(e, 'name')} />
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Format:</div>
      <Input placeholder="" disabled  value={formObj?.format} />
    </div>
    <div className={classNames(style.ctrlBox)}>
      <div className={classNames(style.label)}>Size:</div>
      <Input placeholder="" disabled  value={formObj?.size} />
    </div>
  </div>;
};

export default MediumCom;