import service from '../../../../util/pythonApi';

export function postFile(file, prefix = 'package-ai-screening/rawimage') {
  const data = new FormData();
  data.append('prefix', prefix);
  data.append('form', 'form');
  data.append('file', file);
  return service.request({
    url: '/v1/azure_file',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function putFileKey(fileKey) {
  return service.request({
    url: '/v1/azure_file',
    method: 'put',
    params: {
      file_key: fileKey,
    },
  });
}
