import { Select } from 'antd';
import React from 'react';
import TriangleSvg from '../../assets/img/triangle.svg';
import style from './filter.module.scss';

function Item({ filter, setFilter, label, valueKey, items }) {
  function onChange(val) {
    setFilter({ ...filter, [valueKey]: val });
  }

  return (
    <div className={style.filterItem}>
      <div className={style.filterLabel}>{label}</div>
      <Select
        className={style.filterSelect}
        options={items}
        onChange={onChange}
        placeholder="Select"
        mode='multiple'
      />
    </div>
  );
}

function PackageAiBaseFilter({ filter, setFilter }) {
  return (
    <div className={style.filterBox}>
      <img className={style.filterIcon} src={TriangleSvg} alt="filter" />
      <div className={style.filterBody}>
        <h2>Filter</h2>
        <Item
          filter={filter}
          setFilter={setFilter}
          label="Brand"
          valueKey="brand"
          items={[{ label: 'Brand', value: 'brand' }]}
        />
        <Item
          filter={filter}
          setFilter={setFilter}
          label="Categoty"
          valueKey="categoty"
          items={[{ label: 'Categoty', value: 'categoty' }]}
        />
      </div>
    </div>
  );
}

export default PackageAiBaseFilter;
