import { App, ConfigProvider, theme } from 'antd';
import React from 'react';
import TitlePng from '../assets/img/title.png';
import { PackageAiBtn1 as Btn1 } from './btn.jsx';
import style from './container.module.scss';

function PackageAiContainer({ className = '', children, noDatabase = false }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <div className={`${style.container} ${className}`}>
          <div className={style.containerHeader}>
            <a href="/package-ai-screening">
              <img src={TitlePng} alt="Unmissable Pack AI" />
            </a>
          </div>
          {!noDatabase && (
            <a href="/package-ai-screening-database">
              <Btn1 className={style.btnDatabase}>DATABASE</Btn1>
            </a>
          )}
          <div className={style.containerBody}>{children}</div>
        </div>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiContainer;
