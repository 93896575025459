import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { putFileKey } from '../../apis/file';
import Box from '../../components/box2.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Legend from '../../components/legend.jsx';
import Loading from '../../components/loading.jsx';
import { PackageAiTable as Table } from './table.jsx';
import style from './variant.module.scss';
import ColorCoding from '../../components/colorCoding.jsx';

function DesignUnit({ className = '', data, onImgClick }) {
  return (
    <div
      className={`${style.designUnit} ${className}`}
      style={{ left: `${data.left}%` }}
    >
      <div className={style.designUnitImg}>
        <img
          src={data.url}
          alt={data.title}
          onClick={() => onImgClick(data.url, data.title)}
        />
      </div>
      <div className={style.designUnitTri}></div>
    </div>
  );
}

function Number({ className = '', children }) {
  return <div className={`${style.numberItem} ${className}`}>{children}</div>;
}

function Content({ data = [], onImgClick }) {
  const max = 2;
  const max2 = 4;
  const list = useMemo(() => {
    const ll = [];
    for (const v of data) {
      const { title, value, url } = v;
      const left = (((value + max) / max2) * 0.8 + 0.1) * 100.0;
      ll.push({ title, left, url });
    }
    return ll;
  }, [data]);

  return (
    <>
      <div className={style.box21}>
        {list.map((item, index) => {
          return (
            <DesignUnit
              key={index}
              className={style.box21Rects}
              data={item}
              onImgClick={onImgClick}
            />
          );
        })}
        <div className={style.box21Line} />
        <Number className={`${style.box21Number} ${style.box21Number1}`}>
          -2
        </Number>
        <Number className={`${style.box21Number} ${style.box21Number2}`}>
          -1
        </Number>
        <Number className={`${style.box21Number} ${style.box21Number3}`}>
          0
        </Number>
        <Number className={`${style.box21Number} ${style.box21Number4}`}>
          1
        </Number>
        <Number className={`${style.box21Number} ${style.box21Number5}`}>
          2
        </Number>
      </div>
      <div className={style.box22}>
        <div className={`${style.box22Text} ${style.box22Text1}`}>
          Design skew
          <br />
          towards
          <br />
          brand block
          <br />
          (stronger shelf visibility)
        </div>
        <div className={`${style.box22Text} ${style.box22Text2}`}>
          Balanced design
        </div>
        <div className={`${style.box22Text} ${style.box22Text3}`}>
          Design skew
          <br />
          towards
          <br />
          variant differentiation
          <br />
          (stronger variant findability)
        </div>
      </div>
    </>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // table 1
  const [column1, setColumn1] = useState([
    '',
    'Our Brand Current',
    'Our Brand New Design',
    'Competitor 1',
    'Competitor 2',
  ]);
  const [value1, setValue1] = useState([['Brand-Variant Balance', 0, 0, 0, 0]]);
  const [value2, setValue2] = useState([]);

  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogSrc, setDialogSrc] = useState('');
  const [dialogAlt, setDialogAlt] = useState('');
  function onImgClick(src, alt) {
    setDialogSrc(src);
    setDialogAlt(alt);
    setDialogVisible(true);
  }

  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const suggests = ctx?.res?.variantSuggests.balance;
      if (!suggests) return;
      const column = [''];
      const value = [['Brand-Variant Balance']];
      const value2 = [];

      const processValue = async (name, v, fileKey) => {
        const vas = v?.vasresult;
        if (vas) {
          column.push(name);
          const b = suggests[v.id.toString()];
          {
            const ba = Math.abs(b);
            let cv = 0;
            if (ba < 0.5) {
              cv = 3;
            } else if (ba < 1.5) {
              cv = 2;
            } else {
              cv = 1;
            }
            value[0].push(
              <ColorCoding colorValue={cv}>{b.toFixed(2)}</ColorCoding>
            );
          }
          const { data } = await putFileKey(fileKey);
          value2.push({
            title: name,
            value: b,
            url: data.url,
          });
        }
      };

      // ul brand
      const uld0v = variants[pp.ul_design0_variant0_id];
      await processValue(
        `${pp.brand_ul} Current`,
        uld0v,
        pp.ul_design0_variant0
      );
      const uld1v = variants[pp.ul_design1_variant0_id];
      await processValue(
        `${pp.brand_ul} New Design`,
        uld1v,
        pp.ul_design1_variant0
      );
      const uld2v = variants[pp.ul_design2_variant0_id];
      await processValue(
        `${pp.brand_ul} New Design 2`,
        uld2v,
        pp.ul_design2_variant0
      );
      const uld3v = variants[pp.ul_design3_variant0_id];
      await processValue(
        `${pp.brand_ul} Design 3`,
        uld3v,
        pp.ul_design3_variant0
      );

      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      await processValue(pp.brand_c1, c1d1v, pp.c1_design1_variant0);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      await processValue(pp.brand_c2, c2d1v, pp.c2_design1_variant0);

      setColumn1(column);
      setValue1(value);
      setValue2(value2);
    } catch (err) {
      console.error('init error', err);
      message.error('Get data error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  return (
    <>
      <Table labels={column1} values={value1} />
      <div className={style.legendBox}>
        <Legend />
      </div>
      <Box className={style.box2}>
        <div className={style.box2Inner}>
          <Content data={value2} onImgClick={onImgClick} />
        </div>
      </Box>
      <div className={style.info}>
        Net Balance Score measures the balance of a design between brand and
        variant elements. It’s derived by subtracting the variant
        differentiation from the brand block. A result close to 0 indicates a
        well-balanced design. If the result is above 0, the design is more
        aligned with the brand block and needs more variant differentiation.
        Conversely, a result below 0 suggests the design is heavily
        variant-differentiated.
      </div>
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      {loading && <Loading />}
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogSrc}
        alt={dialogAlt}
      />
    </>
  );
}

export default PackageAiBody;
