import React from 'react';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import style from './confirm.module.scss';

function PackageAiConfirm({ className, onConfirm }) {
  return (
    <div className={`${style.confirmBox} ${className}`}>
      <Btn onClick={onConfirm}>Render</Btn>
    </div>
  );
}

export default PackageAiConfirm;
