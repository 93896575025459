import PropTypes from 'prop-types';
import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import style from './bar.module.scss';

// 动态设置颜色的函数
const getBarColor = (score) => {
  if (Number(score) > 70) {
    return '#00A32E';
  } else if (Number(score) >= 41 && Number(score) <= 70) {
    return '#a39300';
  } else {
    return '#d70728';
  }
};

const BarChartComponent = ({ data = [], showValue = false }) => {
  // 如果没有数据，返回一个默认的空数据
  const chartData = data.length > 0 ? data : [{ name: 'No Data', value: 0 }];

  return (
    <ResponsiveContainer className={style.myChart} width="100%" height="100%">
      <BarChart layout="vertical" data={chartData}>
        <XAxis type="number" hide />
        <YAxis type="category" hide />
        <Bar barSize={28} dataKey="value">
          {/* 动态设置每个条目的颜色 */}
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getBarColor(entry.value)} />
          ))}
          {showValue && (
            <LabelList
              dataKey="value"
              position="inside"
              fill="#fff"
              fontSize={12}
            />
          )}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

// 使用 PropTypes 进行类型检查
BarChartComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      icon: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  showValue: PropTypes.bool,
};

export default BarChartComponent;
