/* eslint-disable no-unused-vars */
import { ConfigProvider, Select, theme } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import BorderContainerDark from '../../components/border-container/border-container-dark.jsx';
import ModalDetail from '../../components/modal/modal-details.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import style from './mintel.module.scss';
// import { fetchSizingAll } from '../../api/index.js';

import imgDemo1 from '../../assets/images/demo_1.png';
import imgDemo2 from '../../assets/images/demo_2.png';

const TrendsDialPage = () => {
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // input value
  const [companyValue, setCompanyValue] = useState('');
  const [regionValue, setRegionValue] = useState('');
  const [dateValue, setdateValue] = useState('');
  const [naturalsValue, setNaturalsValue] = useState('');
  const [convenienceValue, setConvenienceValue] = useState('');
  const [ethicsValue, setEthicsValue] = useState('');
  const [reducedIngredientsValue, setReducedIngredientsValue] = useState('');
  const [addedIngredientsValue, setAddedIngredientsValue] = useState('');
  const [specificDemographicValue, setSpecificDemographicValue] = useState('');
  const [functionalBenefitsValue, setFunctionalBenefitsValue] = useState('');

  // 所有数据
  const [allData, setAllData] = useState([]);
  // 国家
  const [countryList, setCountryList] = useState([]);
  const [countryValue, setCountryValue] = useState('');

  // nutrition 选项
  const [nutritionList, setNutritionList] = useState([]);
  const [currentNutrition, setCurrentNutrition] = useState('');
  const changeCurrentNutrition = (nutrition) => {
    const nutritionOption = nutritionList.find(
      (item) => item.value === nutrition
    ).children;
    setCurrentNutrition(nutrition);
  };

  // 选择国家
  const setCurrentCountryFn = (countryCode) => {
    setCountryValue(countryCode);
    const currentCountryOption = allData.find(
      (item) => item.country === (countryCode === 'GB' ? 'UK' : countryCode)
    );
    const optionList = currentCountryOption.children.map((item) => ({
      label: item.category,
      value: item.category,
      children: item.children,
    }));
    setNutritionList(optionList);
  };

  // setter
  const stateSetters = {
    1: setCompanyValue,
    2: setRegionValue,
    3: setdateValue,
  };

  // options
  const [optionsCpmpany, setOptionsCpmpany] = useState(null);
  const [optionsRegion, setOptionsRegion] = useState(null);
  const [optionsDate, setOptionsDate] = useState(null);
  const [optionsNaturalsValue, setOptionsNaturalsValue] = useState(null);
  const [optionsConvenienceValue, setOptionsConvenienceValue] = useState(null);
  const [optionsEthicsValue, setOptionsEthicsValue] = useState(null);
  const [optionsReducedIngredientsValue, setOptionsReducedIngredientsValue] =
    useState(null);
  const [optionsAddedIngredientsValue, setOptionsAddedIngredientsValue] =
    useState(null);
  const [optionsSpecificDemographicValue, setOptionsSpecificDemographicValue] =
    useState(null);
  const [optionsFunctionalBenefitsValue, setOptionsFunctionalBenefitsValue] =
    useState(null);

  // handle filter
  const handleFilter = (newValue, key) => {
    const setState = stateSetters[key];
    if (setState) {
      setState(newValue);
    }
  };

  // const fetchCountryList = async () => {
  //   const res = await fetchSizingAll();
  //   const countries = res.data
  //     .map((item) => {
  //       return {
  //         label: item.country,
  //         // 前端展示用UK， 其他地方查询用GB
  //         value: item.country === 'UK' ? 'GB' : item.country,
  //       };
  //       // 目前只放开 GB（UK）、US、BR, FR, ID 五个国家
  //     })
  //     .filter((item) => ['GB', 'US', 'BR', 'FR', 'ID'].includes(item.value));
  //   setAllData(res.data);
  //   setCountryList(countries);
  // };

  // Modal
  const [isOpen, setIsOpen] = useState(false);
  const [newModalData, setNewModalData] = useState({});

  const handleImgClick = (id) => {
    const data = {};
    setNewModalData(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   if (countryList.length > 0) return;
  //   fetchCountryList();
  // }, [countryList]);

  useEffect(() => {
    if (allData.length === 0) return;
    setCurrentCountryFn('GB');
  }, [allData]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.fixedContent}>
        <TopBar />
        <div className={style.contentWrap}>
          <BorderContainerDark id="mintel">
            <div className={style.tabBar}>
              <div className={classNames(style.firstTab, style.active)}>
                <span className={style.navTitle}>Innovation Library</span>
                <div className={style.outerCircle}>
                  <div className={style.innerCircle}></div>
                </div>
              </div>
              <div className={style.searchCon}>
                <div className={style.myInputGreen}>
                  <Select
                    placeholder="Country"
                    maxTagCount={1}
                    value={countryValue}
                    onChange={(newValue) => {
                      setCurrentCountryFn(newValue);
                    }}
                    options={countryList}
                    popupMatchSelectWidth={false}
                  />
                </div>
                {/* <div className={style.myInputGreen}>
                  <Select
                    placeholder="Company"
                    showSearch
                    allowClear
                    filterOption={filterOption}
                    onChange={(newValue) => {
                      handleFilter(newValue, 1);
                    }}
                    value={companyValue}
                    options={optionsCpmpany}
                  />
                </div> */}
                <div className={style.myInputGreen}>
                  <Select
                    placeholder="Region"
                    showSearch
                    allowClear
                    filterOption={filterOption}
                    onChange={(newValue) => {
                      handleFilter(newValue, 1);
                    }}
                    value={regionValue}
                    options={optionsRegion}
                  />
                </div>
                <div className={style.myInputGreen}>
                  <Select
                    placeholder="Date"
                    showSearch
                    allowClear
                    filterOption={filterOption}
                    onChange={(newValue) => {
                      handleFilter(newValue, 1);
                    }}
                    value={dateValue}
                    options={optionsDate}
                  />
                </div>
              </div>
            </div>
            <div className={style.tabContent}>
              <div className={style.optWrap}>
                {/* Search */}
                <div className={style.searchWrap}>
                  <div className={style.border}></div>
                  {/* Pack Filter */}
                  <div className={style.title}>
                    <s className={style.icon}></s>
                    <span className={style.text}>Pack Filter</span>
                  </div>
                  <h5 className={style.searchLabel}>Naturals</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Naturals"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      value={currentNutrition}
                      onChange={(newValue) => {
                        changeCurrentNutrition(newValue);
                      }}
                      options={nutritionList}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Convenience</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Convenience"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 2);
                      }}
                      value={convenienceValue}
                      options={optionsConvenienceValue}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Ethics/Environmental</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Ethics/Environmental"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 3);
                      }}
                      value={ethicsValue}
                      options={optionsEthicsValue}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Reduced Ingredients</h5>
                  <div className={classNames(style.myInput, style.mb25)}>
                    <Select
                      placeholder="Reduced Ingredients"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 4);
                      }}
                      value={reducedIngredientsValue}
                      options={optionsReducedIngredientsValue}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Added Ingredients</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Added Ingredients"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 5);
                      }}
                      value={addedIngredientsValue}
                      options={optionsAddedIngredientsValue}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Specific Demographic</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Specific Demographic"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 6);
                      }}
                      value={specificDemographicValue}
                      options={optionsSpecificDemographicValue}
                    />
                  </div>
                  <h5 className={style.searchLabel}>Functional Benefits</h5>
                  <div className={style.myInput}>
                    <Select
                      placeholder="Functional Benefits"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      onChange={(newValue) => {
                        handleFilter(newValue, 7);
                      }}
                      value={functionalBenefitsValue}
                      options={optionsFunctionalBenefitsValue}
                    />
                  </div>
                </div>
                {/* List */}
                <div className={style.ListWrap}>
                  <div
                    className={style.listItem}
                    onClick={() => {
                      handleImgClick();
                    }}
                  >
                    <div className={style.imgWrap}>
                      <img src={imgDemo1} alt="" />
                    </div>
                    <div className={style.text}>123123321123</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo2} alt="" />
                    </div>
                    <div className={style.text}>dfgsfdsgsdf</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo1} alt="" />
                    </div>
                    <div className={style.text}>123123321123</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo2} alt="" />
                    </div>
                    <div className={style.text}>dfgsfdsgsdf</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo1} alt="" />
                    </div>
                    <div className={style.text}>123123321123</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo2} alt="" />
                    </div>
                    <div className={style.text}>dfgsfdsgsdf</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo1} alt="" />
                    </div>
                    <div className={style.text}>123123321123</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo2} alt="" />
                    </div>
                    <div className={style.text}>dfgsfdsgsdf</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo1} alt="" />
                    </div>
                    <div className={style.text}>123123321123</div>
                  </div>
                  <div className={style.listItem}>
                    <div className={style.imgWrap}>
                      <img src={imgDemo2} alt="" />
                    </div>
                    <div className={style.text}>dfgsfdsgsdf</div>
                  </div>
                </div>
              </div>
            </div>
          </BorderContainerDark>
        </div>
        {/* Modal */}
        <ModalDetail
          open={isOpen}
          data={newModalData}
          closeModal={closeModal}
        />
      </div>
    </ConfigProvider>
  );
};

export default TrendsDialPage;
