// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sSWZ24C8gwyM8mlGyPwr{height:100%;padding:30px;box-sizing:border-box;display:flex;flex-direction:column}.ohpXRtg3BLQsGYRTDo3M{flex:1;margin-top:20px;display:grid;grid-template-columns:repeat(2, 1fr);grid-row-gap:24px;grid-column-gap:24px;overflow-x:hidden;overflow-y:auto}.Ax4Mdwdx9GIzLyFdxvIw{width:100%;padding-top:100%;height:0;position:relative;border-radius:10px;overflow:hidden;cursor:pointer}.Ax4Mdwdx9GIzLyFdxvIw img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:contain;display:block}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/library.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,YAAA,CACA,oCAAA,CACA,iBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,gBAAA,CACA,QAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,0BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".libraryBox {\n  height: 100%;\n  padding: 30px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n}\n\n.libraryBody {\n  flex: 1;\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-row-gap: 24px;\n  grid-column-gap: 24px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.itemBox {\n  width: 100%;\n  padding-top: 100%;\n  height: 0;\n  position: relative;\n  border-radius: 10px;\n  overflow: hidden;\n  cursor: pointer;\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"libraryBox": `sSWZ24C8gwyM8mlGyPwr`,
	"libraryBody": `ohpXRtg3BLQsGYRTDo3M`,
	"itemBox": `Ax4Mdwdx9GIzLyFdxvIw`
};
export default ___CSS_LOADER_EXPORT___;
