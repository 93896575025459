import React from 'react';
import BallPng from '../../../assets/images/home/ball.png';
import BallBtnPng from '../../../assets/images/home/btn1.png';
import BallBtnSvg from '../../../assets/images/home/btn2.svg';
import style from './ball.module.scss';

export function OccasionHomeBallItem({ item, desc, url, descAtLeft }) {
  return (
    <div className={style.ballItem} key={item}>
      <a href={url}>
        <img className={style.ballItemBtn} src={BallBtnPng} />
        <div className={style.ballItemText}>{item}</div>
      </a>
      <div
        className={`${style.ballItemDesc} ${
          descAtLeft ? style.ballItemDescLeft : style.ballItemDescRight
        }`}
        style={{ backgroundImage: `url(${BallBtnSvg})` }}
      >
        <div className={style.ballItemDescText}>{desc}</div>
      </div>
    </div>
  );
}

export function OccasionHomeBall({
  title,
  items,
  descAtLeft,
  itemsDisplay,
  onClick,
}) {
  return (
    <div
      className={`${style.ballContainer} ${
        itemsDisplay ? style.ballContainerActive : ''
      }`}
      onClick={onClick}
    >
      <img className={style.ballImg} src={BallPng} alt="unilever" />
      <h2 className={style.ballTitle}>{title}</h2>
      <div
        className={style.ballItems}
        style={{ display: itemsDisplay ? '' : 'none' }}
      >
        {items.map((e) => OccasionHomeBallItem({ ...e, descAtLeft }))}
      </div>
    </div>
  );
}
