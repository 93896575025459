import { SearchOutlined } from '@ant-design/icons';
import { App, ConfigProvider, Input, message, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { getPackageAiAsset } from '../../apis/serve/asset';
import Box from '../../components/box.jsx';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Filter from './filter.jsx';
import style from './index.module.scss';
import Item from './item.jsx';

function PackageAiView() {
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState({});
  const [list, setList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);

  async function readAsset(clear = false) {
    if (loading) return;
    try {
      setLoading(true);
      const limit = clear ? 18 : 6;
      const { data } = await getPackageAiAsset(
        { ...filter, search },
        clear ? 0 : list.length,
        limit,
        'package'
      );
      if (data) {
        const { data: dd } = data;
        setIsNoMore(dd.length < limit);
        if (clear) {
          setList(dd);
        } else {
          setList([...list, ...dd]);
        }
      } else {
        console.warn('read asset fail', data);
        message.warning('Read asset fail');
      }
    } catch (err) {
      console.error('read asset error', err);
      message.error('Read asset error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void readAsset(true);
  }, [search, filter]);

  function onMore() {
    if (isNoMore) return;
    void readAsset();
  }

  async function onNext() {
    if (selectedIndex < 0) {
      message.warning('Please select a package first');
      return;
    }
    if (loading) return;
    setLoading(true);
    try {
      const item = list[selectedIndex];
      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          base: {
            url: item.url,
          },
        })
      );
      window.open('/package-ai-generation2', '_self');
    } catch (err) {
      console.error('upload error', err);
      message.error('Upload image fail');
    } finally {
      setLoading(false);
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={1}>
          <Box
            className={style.box}
            hasHeader={true}
            title="Choose a package"
            headerRight={
              <Input
                style={{ width: '250px' }}
                value={search}
                onChange={setSearch}
                prefix={<SearchOutlined></SearchOutlined>}
              ></Input>
            }
          >
            <div className={style.boxBody}>
              <div className={style.content}>
                <Filter filter={filter} setFilter={setFilter} />
                <div className={style.boxLine}></div>
                <div className={style.itemList}>
                  {list.map((item, index) => (
                    <Item
                      data={item}
                      key={`${item.title}-${index}`}
                      selected={index === selectedIndex}
                      onSelect={() => setSelectedIndex(index)}
                    />
                  ))}
                </div>
              </div>
              <div className={style.btnBox}>
                <Btn
                  disabled={!selectedIndex < 0}
                  loading={loading}
                  onClick={onNext}
                >
                  NEXT
                </Btn>
              </div>
              <div className={style.info}>
                * Due to privacy legislation all insights & photos / videos can
                only be used internally,
                <br />
                and only after verbal consent from the audience. Ideally, no
                personal data is
                <br />
                obtained and any personal data obtained anonymized.*
              </div>
            </div>
          </Box>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
