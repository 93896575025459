import { SearchOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getPackageAiAsset } from '../../apis/serve/asset.js';
import style from './library.module.scss';

function PackageAiItem({ data, onClick }) {
  return (
    <div className={style.itemBox} onClick={(ev) => onClick(ev, data)}>
      <img
        src={data.url}
        alt={data.title}
        loading="lazy"
        crossOrigin="anonymous"
      />
    </div>
  );
}

function PackageAiLibrary({ className, addImage }) {
  const [search, setSearch] = useState();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  function onItemClick(ev, data) {
    if (!ev.target) return;
    addImage(data.url, {
      width: ev.target.naturalWidth,
      height: ev.target.naturalHeight,
    });
  }

  async function readAssets(clear = false) {
    if (loading) return;
    try {
      setLoading(true);
      const { data } = await getPackageAiAsset(
        { search },
        clear ? 0 : list.length,
        6,
        'logo'
      );
      if (data) {
        const { data: dd } = data;
        if (clear) {
          setList(dd);
        } else {
          setList([...list, ...dd]);
        }
      } else {
        console.warn('read asset fail', data);
        message.warning('Read asset fail');
      }
    } catch (err) {
      console.error('read asset error', err);
      message.error('Read asset error');
    }
    setLoading(false);
  }

  useEffect(() => {
    void readAssets(true);
  }, [search]);

  function onMore() {
    void readAssets();
  }

  return (
    <div className={`${style.libraryBox} ${className}`}>
      <div className={style.libraryHeader}>
        <Input
          style={{ width: '100%' }}
          value={search}
          onChange={setSearch}
          prefix={<SearchOutlined></SearchOutlined>}
        ></Input>
      </div>
      <div className={style.libraryBody}>
        {list.map((item) => (
          <PackageAiItem
            key={item.id}
            data={item}
            onClick={onItemClick}
          ></PackageAiItem>
        ))}
      </div>
    </div>
  );
}

export default PackageAiLibrary;
