/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
import {
  allStyleCountry,
  allTitle,
  other,
  styleData,
} from '@/modules/top-dish/components/list-main/SpreadSheet';
import EventBus from '@/modules/top-dish/hooks/eventBus';
import { Button, Tooltip } from 'antd';
import { flatten, reverse, sortBy, uniq } from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import djtx from '../../img/djtx.png';
import djty from '../../img/djty.png';
import icon2 from '../../img/icon21.png';
import icon3 from '../../img/icon22.png';
import jt1s from '../../img/jt1s.png';
import jt1x from '../../img/jt1x.png';
import jt2s from '../../img/jt2s.png';
import jt2x from '../../img/jt2x.png';
import noData from '../../img/noData.png';
import tips1 from '../../img/tips1.png';
import tips2 from '../../img/tips2.png';
import style from './list.module.scss';

import icon31 from '../../img/icon31.svg';
import icon32 from '../../img/icon32.svg';
import icon33 from '../../img/icon33.svg';
import icon34 from '../../img/icon34.svg';
import icon35 from '../../img/icon35.svg';

const App = () => {
  const [, forceRerender] = useReducer((x) => x + 1, 0);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState([]);
  const [styleType, setStyleType] = useState([]);
  const [showTips, setShowTips] = useState(true);
  const [showDeep, setShowDeep] = useState(false);
  const [tipsString, setTipsString] = useState('');
  const [lajihua, setLajihua] = useState('');
  const [topStyle, setTopStyle] = useState(false);
  const emergingRef = useRef(null);

  const [type, setType] = useState({
    country: 'United States',
    topType: 'dataA',
    ketchup: false,
    title: 'Top Dishes in the United States',
    sort: [0, 0],
  });

  const typeIcon = useMemo(() => {
    if (type.topType === 'dataA') {
      return icon31;
    } else if (type.topType === 'dataB') {
      return icon32;
    } else if (type.topType === 'dataC') {
      return type.ketchup ? icon35 : icon33;
    } else if (type.topType === 'dataD') {
      return icon34;
    }
  }, [type.topType, type.ketchup]);

  useEffect(() => {
    //挂载
    EventBus.addEventListener('topDishListEvent', topDishListEvent);
    EventBus.addEventListener('topDishHeaderEvent', topDishHeaderEvent);
    EventBus.addEventListener(
      'topDishHeaderKetchupEvent',
      topDishHeaderKetchupEvent,
    );
    return () => {
      //卸载
      EventBus.removeEventListener('topDishListEvent', topDishListEvent);
      EventBus.removeEventListener('topDishHeaderEvent', topDishHeaderEvent);
      EventBus.removeEventListener(
        'topDishHeaderKetchupEvent',
        topDishHeaderKetchupEvent,
      );
    };
  }, [allData, title]);

  const topDishHeaderKetchupEvent = (e) => {
    type.ketchup = e.detail.ketchup;
  };

  const topDishListEvent = (e) => {
    type.country = e.detail.country;
    type.title = allTitle[e.detail.country][type.topType];
    setStyleType(allStyleCountry[e.detail.country][type.topType]);
    setAllData(e.detail.data);
    let bTitle = [];
    for (let i of e.detail.data[type.topType].title) {
      bTitle.push(i);
      if (typeof i === 'string' && i.split('<').length > 1) {
        for (let j = 1; j < i.split('<').length; j++) {
          bTitle.push('空');
        }
      }
    }
    setTitle(bTitle);
    console.log('data接收到传值啦！~~~~~~', e.detail.data);
    setShowTips(true);
    splitData(e.detail.data[type.topType].body);
    setOtherString();
  };
  const topDishHeaderEvent = (e) => {
    type.topType = e.detail;
    type.title = allTitle[type.country][e.detail];
    setStyleType(allStyleCountry[type.country][e.detail]);
    let bTitle = [];
    for (let i of allData[e.detail].title) {
      bTitle.push(i);
      if (typeof i === 'string' && i.split('<').length > 1) {
        for (let j = 1; j < i.split('<').length; j++) {
          bTitle.push('空');
        }
      }
    }
    setTitle(bTitle);
    console.log('header接收到传值啦！~~~~~~', allData[e.detail]);
    setShowTips(true);
    splitData(allData[e.detail].body);
    setOtherString();
  };

  const setOtherString = () => {
    let str = '';
    switch (type.topType) {
      case 'dataA':
        str = 'o1';
        break;
      case 'dataB':
        str = 'o2';
        break;
      case 'dataC':
        str = 'o3';
        break;
      case 'dataD':
        str = 'o4';
        break;
    }
    setTipsString(other[type.country]?.[str]);
    setLajihua(other[type.country]?.[str + 'la']);
  };

  let splitData = (data) => {
    let dataNew = [{ list: [] }];
    let idx = 0;
    for (let i in data) {
      let newArr = data[i].filter(
        (i) => i && (typeof i === 'number' || i.trim()),
      );
      newArr = newArr.map((i) => {
        // if (i && typeof i === 'number')
        //   return i.toFixed(1);
        return i;
      });
      if (newArr.length === 1) {
        if (Number(i) !== 0) idx += 1;
        dataNew[idx] = { title: newArr[0], list: [] };
        continue;
      }
      dataNew[idx].list.push(newArr);
    }
    setData(dataNew);
    console.log('==================', dataNew);
  };

  let titleSort = (idx) => {
    if (idx === type.sort[0]) {
      if (type.sort[1]) {
        type.sort[1] = 0;
      } else {
        type.sort[1] = 1;
      }
    } else {
      type.sort = [idx, 0];
    }
    let sortData = data.map((item) => {
      let l = sortBy(item.list, (i) => {
          let a = i[type.sort[0]].split('^')[0] = i[type.sort[0]].split('<')[0];
          return isNaN(Number(a)) ? a : Number(a);
        },
      );
      if (type.sort[1]) l = reverse(l);
      return { title: item.title, list: l };
    });

    let isLow = uniq(
      flatten(
        data.map((item) =>
          item.list.map((i) =>
            ['LOW', 'MEDIUM', 'HIGH'].includes(i[type.sort[0]]),
          ),
        ),
      ),
    )[0];

    if (isLow) {
      sortData = data.map((item) => {
        let l = sortBy(item.list, (i) => {
          let a = i[type.sort[0]];
          return (
            (a === 'LOW' && 1) || (a === 'MEDIUM' && 2) || (a === 'HIGH' && 3)
          );
        });
        if (type.sort[1]) l = reverse(l);
        return { title: item.title, list: l };
      });
    }

    if (data[1]?.list?.length) {
      let content = data[1].list.map((item) => item[0]);
      content = content.filter(
        (i) => typeof i == 'string' && i.split('^').length > 1,
      );
      if (content.length) {
        let dList = [];
        content.map((i) => {
          let spTitle = i.split('^');
          let spIndex = data[1].list.findIndex((j) => j[0] === i);
          let listTitle = data[1].list[spIndex];
          let list = data[1].list.slice(
            spIndex + 1,
            spIndex + Number(spTitle[1]),
          );

          let l = sortBy(list, (j) => j[type.sort[0]]);
          if (type.sort[1]) l = reverse(l);
          dList.push(listTitle, ...l);
        });
        sortData[1].list = dList;
      }
    }

    setData(sortData);
    forceRerender();
  };

  function getImgUrl(idx, up) {
    if (up) {
      return idx === type.sort[0] && !type.sort[1] ? jt1s : jt2s;
    }
    return idx === type.sort[0] && type.sort[1] ? jt1x : jt2x;
  }

  function titleSplit1(str) {
    if (!str) return null;
    const c = str.split('^')[0];
    if (!c) return null;
    return c.split('<')[0];
  }

  function titleSplit2(idx) {
    let a = title[idx]?.split('^')[1];
    let b = title[idx - 1]?.split('^')[1];
    let c = title[idx - 2]?.split('^')[1];
    let d = title[idx - 3]?.split('^')[1];
    if (a?.split('<').length > 1) return a.split('<')[0];
    if (b?.split('<').length > 1) return b.split('<')[1];
    if (c?.split('<').length > 2) return c.split('<')[2];
    if (d?.split('<').length > 3) return d.split('<')[3];
    return a;
  }

  function onShowTips() {
    setShowTips(!showTips);
  }

  function onShowDeep() {
    setShowDeep(!showDeep);
  }

  const HandleScroll = (e) => {
    const winHeight = window.innerHeight;
    const scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0);
    if (winHeight < scrollTop) {
      setTopStyle(true);
    } else {
      setTopStyle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', HandleScroll);

    return () => {
      window.removeEventListener('scroll', HandleScroll);
    };
  }, []);

  function toEmergingRef() {
    if (!emergingRef.current) return;
    emergingRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  // ==================下面是dom渲染===================

  function getTips({ onShowTips }) {
    return (
      showTips &&
      tipsString && (
        <div
          className={style.tips1}
          dangerouslySetInnerHTML={{ __html: tipsString }}
          onClick={onShowTips}
        ></div>
      )
    );
  }

  function getDom(item, idx) {
    let str = item[idx];
    let sty = styleData[styleType[idx]];
    let laStr = '';
    if (typeof item[idx] == 'string' && item[idx].indexOf('<la') > -1) {
      str = str.split('<la')[0];
      laStr = item[idx].split('<la')[1];
    }
    if (typeof item[idx] == 'string' && item[idx].split('^').length > 1) {
      sty = { ...sty, fontWeight: 'bold' };
      str = str.split('^')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<r') > -1) {
      sty = { ...sty, color: '#DA0000' };
      str = str.split('<r')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<g') > -1) {
      sty = { ...sty, color: '#00A32E' };
      str = str.split('<g')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<y') > -1) {
      sty = { ...sty, color: '#FFBB38' };
      str = str.split('<y')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<p') > -1) {
      sty = { ...sty, color: '#FF7C7D' };
      str = str.split('<p')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<d') > -1) {
      sty = { ...sty, color: '#00A889' };
      str = str.split('<d')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<b') > -1) {
      sty = { ...sty, color: '#005EEF' };
      str = str.split('<b')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<z') > -1) {
      sty = { ...sty, color: '#71717A' };
      str = str.split('<z')[0];
    }
    if (typeof item[idx] == 'string' && item[idx].indexOf('<zo') > -1) {
      sty = styleData[styleType[idx]];
      str = str.split('<zo')[0];
    }

    if (laStr) {
      return (
        <div style={sty}>
          <Tooltip title={laStr} overlayInnerStyle={{ fontSize: '11px' }}>
            <span style={{ cursor: 'pointer' }}>{str}</span>
          </Tooltip>
        </div>
      );
    }

    return <div style={sty}>{str}</div>;
  }

  function domList() {
    return data.map((t) => {
      if (
        t.title &&
        typeof t.title == 'string' &&
        t.title.indexOf('DEEP-DIVE') > -1
      ) {
        return (
          <>
            {
              <div className={style.deepDive} onClick={onShowDeep}>
                {/*<img className={style.dquan} src={dquan} alt="" />*/}
                {t.title}
                {showDeep && <img className={style.djtx} src={djtx} alt="" />}
                {!showDeep && <img className={style.djty} src={djty} alt="" />}
              </div>
            }
            {showDeep &&
              t.list.map((item, i) => {
                return (
                  <>
                    <div className={style.listContent} key={i}>
                      {getDom(item, 0)}
                      {getDom(item, 1)}
                      {getDom(item, 2)}
                      {getDom(item, 3)}
                      {getDom(item, 4)}
                      {getDom(item, 5)}
                      {getDom(item, 6)}
                      {getDom(item, 7)}
                      {getDom(item, 8)}
                      {getDom(item, 9)}
                    </div>
                  </>
                );
              })}
          </>
        );
      }
      return (
        <>
          {t.title && <div style={styleData['writeTitle']}>{t.title}</div>}
          {t.list.map((item, i) => {
            return (
              <>
                <div className={style.listContent} key={i}>
                  {getDom(item, 0)}
                  {getDom(item, 1)}
                  {getDom(item, 2)}
                  {getDom(item, 3)}
                  {getDom(item, 4)}
                  {getDom(item, 5)}
                  {getDom(item, 6)}
                  {getDom(item, 7)}
                  {getDom(item, 8)}
                  {getDom(item, 9)}
                </div>
              </>
            );
          })}
        </>
      );
    });
  }

  function isMerge(idx, title2) {
    if (title[idx] === '空') return null;

    const boxClass = `${style.text3} ${title2 ? '' : style.notHasTitle2}`;

    if (title[idx]?.split('<').length < 2) {
      if (title[idx]?.split('^').length > 1) {
        return (
          <div className={boxClass} style={styleData[styleType[idx]]}>
            {titleSplit1(title[idx])}
          </div>
        );
      }
      return (
        <div className={boxClass} style={styleData[styleType[idx]]}>
          {titleSplit1(title[idx])}
          <div className={style.jtBox} onClick={(e) => titleSort(idx, e)}>
            <img className={style.jt1} src={getImgUrl(idx, 1)} />
            <img className={style.jt2} src={getImgUrl(idx, 0)} />
          </div>
        </div>
      );
    }

    if (title[idx]?.split('<').length > 3)
      return (
        <div className={boxClass} style={other[type.country].mergeWidth3}>
          {titleSplit1(title[idx])}
        </div>
      );
    if (title[idx]?.split('<').length > 2)
      return (
        <div className={boxClass} style={other[type.country].mergeWidth2}>
          {titleSplit1(title[idx])}
        </div>
      );
    if (title[idx]?.split('<').length > 1)
      return (
        <div className={boxClass} style={other[type.country].mergeWidth1}>
          {titleSplit1(title[idx])}
        </div>
      );
  }

  function titleTwo(idx, text) {
    return (
      <div
        className={`${text ? '' : style.empty}`}
        style={styleData[styleType[idx]]}
      >
        <text>{text}</text>
        {text && (
          <div className={style.jtBox} onClick={(e) => titleSort(idx, e)}>
            <img className={style.jt1} src={getImgUrl(idx, 1)} />
            <img className={style.jt2} src={getImgUrl(idx, 0)} />
          </div>
        )}
      </div>
    );
  }

  function titleList() {
    const titles2 = [];
    for (let i = 0; i < 10; i++) {
      titles2.push(titleSplit2(i));
    }

    const hasTitle2 = titles2.some((i) => i);

    return (
      <div className={style.listTitleBox}>
        <div
          className={`${style.listTitle1} ${hasTitle2 ? style.hasTitle2 : ''}`}
        >
          {isMerge(0, titles2[0])}
          {isMerge(1, titles2[1])}
          {isMerge(2, titles2[2])}
          {isMerge(3, titles2[3])}
          {isMerge(4, titles2[4])}
          {isMerge(5, titles2[5])}
          {isMerge(6, titles2[6])}
          {isMerge(7, titles2[7])}
          {isMerge(8, titles2[8])}
          {isMerge(9, titles2[9])}
        </div>
        {hasTitle2 && (
          <div className={style.listTitle2}>
            {titleTwo(0, titles2[0])}
            {titleTwo(1, titles2[1])}
            {titleTwo(2, titles2[2])}
            {titleTwo(3, titles2[3])}
            {titleTwo(4, titles2[4])}
            {titleTwo(5, titles2[5])}
            {titleTwo(6, titles2[6])}
            {titleTwo(7, titles2[7])}
            {titleTwo(8, titles2[8])}
            {titleTwo(9, titles2[9])}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={style.content}>
      <div className={topStyle && style.djTop}>
        <div className={style.title}>
          <div className={style.titleLeft}>
            <img src={typeIcon} alt="" />
            <div className={style.text1}>{type.title}</div>
            <div className={style.foldBtn} onClick={onShowTips}>
              <img
                className={showTips ? style.active : ''}
                src={icon2}
                alt={showTips ? tips1 : tips2}
              />
            </div>
          </div>
          <div className={style.titleRight}>
            <Button
              style={{ display: 'flex' }}
              type="primary"
              icon={<img src={icon2} />}
              onClick={toEmergingRef}
            >
              <span className={style.text2}>Emerging Top Dish</span>
            </Button>
            <Button
              style={{
                display: 'flex',
                background: '#013B02',
                marginLeft: '20px',
              }}
              type="primary"
              icon={<img src={icon3} alt="" />}
            >
              <span className={style.text2}>Export</span>
            </Button>
          </div>
        </div>
        {getTips({ onShowTips })}
        {titleList()}
      </div>
      <div className={topStyle && style.djTopNone}></div>

      {/*// 循环渲染表格*/}

      {domList()}
      {/*// 循环渲染表格*/}

      {lajihua && (
        <div
          className={style.lajihua}
          dangerouslySetInnerHTML={{ __html: lajihua }}
        ></div>
      )}

      <div className={style.title}>
        <div className={style.titleLeft}>
          {/* <img src={icon1} alt="" /> */}
          {/* <div>{type.title}</div> */}
        </div>
        <div className={style.titleRight}>
          <Button
            style={{
              display: 'flex',
              background: '#013B02',
              marginLeft: '20px',
            }}
            type="primary"
            icon={<img src={icon3} alt="" />}
          >
            <span className={style.text2}>Export</span>
          </Button>
        </div>
      </div>
      {/*{titleList()}*/}
      <div className={style.dataComing}>
        <img src={noData} alt="Data Coming soon" ref={emergingRef} />
      </div>
    </div>
  );
};

export default App;
