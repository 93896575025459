import { message } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Group,
  Image as KonvaImage,
  Layer,
  Stage,
  Text as KonvaText,
  Transformer,
} from 'react-konva';
import useImage from 'use-image';
import GrabrashSvg from '../../assets/img/grabrash-r.svg';
import style from './editor.module.scss';

const TEXT_MIN_WIDTH = 50;

function PackageAiEditorDelete({ scale = 1, onClick, x, y }) {
  const [image] = useImage(GrabrashSvg);

  const margin = useMemo(() => {
    return 5 / scale;
  }, [scale]);
  const size = useMemo(() => {
    return 15 / scale;
  }, [scale]);

  return (
    <KonvaImage
      x={x - size - margin}
      y={y + margin}
      width={size}
      height={size}
      image={image}
      onClick={onClick}
    />
  );
}

function PackAiEditorImage({
  index,
  item,
  onDragStart,
  onDragMove,
  onDragEnd,
  setItem,
  selectItem,
  draggable = true,
  onDelete,
  canSelect = true,
  scale = 1,
}) {
  const [image] = useImage(item.url, 'anonymous');

  const transformerRef = useRef();
  const groupRef = useRef();
  const imgRef = useRef();

  const selected = useMemo(() => {
    if (!canSelect) return false;
    return item.selected;
  }, [canSelect, item]);

  // init transformer
  useEffect(() => {
    if (!selected) return;
    if (!groupRef.current || !transformerRef.current) return;
    transformerRef.current.nodes([groupRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, [selected, image]);

  const height = useMemo(
    () => item.height ?? image?.height ?? 50,
    [item.height, image]
  );
  const width = useMemo(
    () => item.width ?? image?.width ?? 50,
    [item.width, image]
  );

  function onSelect(ev) {
    ev.cancelBubble = true;
    selectItem(index);
  }

  function onTransformEnd() {
    const group = groupRef.current;
    const img = imgRef.current;
    if (!group || !img) return;
    setItem(index, {
      ...item,
      x: group.x(),
      y: group.y(),
      width: img.width() * img.scaleX(),
      height: img.height() * img.scaleY(),
    });
    img.scaleX(1);
    img.scaleY(1);
  }

  if (!image) return null;

  return (
    <>
      <Group
        ref={groupRef}
        x={item.x}
        y={item.y}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
      >
        <KonvaImage
          ref={imgRef}
          image={image}
          width={width}
          height={height}
          onClick={onSelect}
        />
        {selected && (
          <PackageAiEditorDelete
            x={width}
            y={0}
            scale={scale}
            onClick={onDelete}
          />
        )}
      </Group>
      {selected && (
        <Transformer
          ref={transformerRef}
          resizeEnabled={draggable}
          rotateEnabled={false}
          enabledAnchors={[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ]}
          anchorSize={8 / scale}
          anchorStrokeWidth={1 / scale}
          borderStrokeWidth={1 / scale}
        />
      )}
    </>
  );
}

function PackageAiText({
  index,
  item,
  onDragStart,
  onDragMove,
  onDragEnd,
  setItem,
  selectItem,
  draggable = true,
  onDelete,
  canSelect = true,
  scale = 1,
}) {
  const textRef = useRef();
  const transformerRef = useRef();
  const groupRef = useRef();
  function onSelect(ev) {
    ev.cancelBubble = true;
    selectItem(index);
  }

  const selected = useMemo(() => {
    if (!canSelect) return false;
    return item.selected;
  }, [canSelect, item]);

  const [width, setWidth] = useState(item.width);

  // init transformer
  useEffect(() => {
    if (!selected) return;
    if (!groupRef.current || !transformerRef.current) return;
    transformerRef.current.nodes([groupRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, [selected]);

  function onTransform() {
    const node = groupRef.current;
    if (!node) return;

    // set item
    const width = node.width() * node.scaleX();
    if (width < TEXT_MIN_WIDTH) return;
    setWidth(width);
    setItem(index, {
      ...item,
      x: node.x(),
      y: node.y(),
      width,
    });
    node.scaleX(1);
  }

  return (
    <>
      <Group
        ref={groupRef}
        x={item.x}
        y={item.y}
        width={width}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransform={onTransform}
      >
        <KonvaText
          ref={textRef}
          width={width}
          text={item.text}
          fontFamily={item.fontFamily}
          fontSize={item.fontSize}
          align={item.textAlign}
          fill={item.color}
          onClick={onSelect}
        />
        {selected && (
          <PackageAiEditorDelete
            x={width}
            y={0}
            scale={scale}
            onClick={onDelete}
          />
        )}
      </Group>
      {selected && (
        <Transformer
          ref={transformerRef}
          resizeEnabled={draggable}
          rotateEnabled={false}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(ob, nb) => {
            if (nb.width < TEXT_MIN_WIDTH) return ob;
            return nb;
          }}
          anchorSize={8 / scale}
          anchorStrokeWidth={1 / scale}
          borderStrokeWidth={1 / scale}
        />
      )}
    </>
  );
}

function PackAiEditorItem({
  item,
  index,
  setItem,
  selectItem,
  draggable = true,
  onDelete = () => void 0,
  canSelect = true,
  scale = 1,
}) {
  function onDragStart() {
    setItem && setItem(index, { ...item, isDragging: true });
  }
  function onDragEnd(e) {
    setItem &&
      setItem(index, {
        ...item,
        isDragging: false,
        x: e.target.getX(),
        y: e.target.getY(),
      });
  }

  if (item.type === 'image') {
    return (
      <PackAiEditorImage
        key={index}
        index={index}
        item={item}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        setItem={setItem}
        selectItem={selectItem}
        draggable={draggable}
        onDelete={() => onDelete(index)}
        canSelect={canSelect}
        scale={scale}
      />
    );
  } else if (item.type === 'text') {
    return (
      <PackageAiText
        key={index}
        index={index}
        item={item}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        setItem={setItem}
        selectItem={selectItem}
        draggable={draggable}
        onDelete={() => onDelete(index)}
        canSelect={canSelect}
        scale={scale}
      />
    );
  } else {
    return null;
  }
}

function PackageAiEditor({
  url,
  scale = 1,
  setScale,
  resetCount = 0,
  cursor = 1,
  data,
  setData,
  setDrawRef,
  setLoading,
}) {
  const [image, imageStatus] = useImage(url, 'anonymous');
  useEffect(() => {
    if (imageStatus === 'failed') {
      message.error('Failed to load image');
      setLoading(false);
    } else if (imageStatus === 'loaded') {
      // reset scale
      setScale(1);
      setLoading(false);
    } else if (imageStatus === 'loading') {
      setLoading(true);
    } else {
      console.warn('unknown image status', imageStatus);
    }
  }, [imageStatus]);

  const boxRef = useRef(null);
  const contain = useMemo(() => {
    if (!image || !boxRef.current) {
      return { width: 0, height: 0, scale: 1 };
    }
    const boxWidth = boxRef.current.clientWidth;
    const boxHeight = boxRef.current.clientHeight;
    const boxRatio = boxWidth / boxHeight;
    const imageWidth = image.width;
    const imageHeight = image.height;
    const imageRatio = imageWidth / imageHeight;
    if (boxRatio > imageRatio) {
      const scale = boxHeight / imageHeight;
      return {
        width: imageWidth,
        height: imageHeight,
        scale: scale,
      };
    } else {
      const scale = boxWidth / imageWidth;
      return {
        width: imageWidth,
        height: imageHeight,
        scale: scale,
      };
    }
  }, [image, boxRef.current]);
  useEffect(() => {
    setScale(contain.scale);
  }, [contain.scale]);

  function setItem(index, item) {
    setData((d) => {
      const newData = [...d];
      newData[index] = item;
      return newData;
    });
  }
  function selectItem(index) {
    setData((d) => {
      return d.map((e, i) => {
        return {
          ...e,
          selected: i === index,
        };
      });
    });
  }
  function onDeleteItem(index) {
    setData((d) => {
      const newData = [...d];
      newData.splice(index, 1);
      return newData;
    });
  }

  const drawRef = useRef(null);
  useEffect(() => {
    setDrawRef(drawRef.current);
  }, [drawRef.current]);

  const [isGrabbing, setIsGrabbing] = useState(false);
  const [grabPos, setGrabPos] = useState({
    startX: 0,
    startY: 0,
    left: 0,
    top: 0,
  });
  const cursorStyle = useMemo(() => {
    if (cursor === 2) {
      if (isGrabbing) return 'grabbing';
      else return 'grab';
    }
    return 'auto';
  }, [cursor, isGrabbing]);

  function handleMouseDown(e) {
    const pos = e.target.getStage().getPointerPosition();
    if (cursor === 2) {
      setIsGrabbing(true);
      setGrabPos((p) => {
        return {
          ...p,
          startX: pos.x,
          startY: pos.y,
        };
      });
    }
  }
  function handleMouseMove(e) {
    const pos = e.target.getStage().getPointerPosition();
    if (cursor === 2) {
      if (!isGrabbing) return;
      setGrabPos((p) => {
        const { startX, startY, left, top } = p;
        const dx = pos.x - startX;
        const dy = pos.y - startY;
        return {
          ...p,
          left: left + dx,
          top: top + dy,
        };
      });
    }
  }
  function handleMouseUp() {
    if (cursor === 2) {
      setIsGrabbing(false);
    }
  }

  function onReset() {
    setGrabPos({
      startX: 0,
      startY: 0,
      left: 0,
      top: 0,
    });
  }
  useEffect(() => {
    if (resetCount) onReset();
  }, [resetCount]);

  function onStageClick() {
    setData((d) => d.map((e) => ({ ...e, selected: false })));
  }

  if (!image) return null;

  return (
    <div className={style.editorBox} ref={boxRef}>
      <div className={style.editorInner}>
        <Stage
          width={contain.width}
          height={contain.height}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onClick={onStageClick}
          style={{
            position: 'relative',
            left: `${grabPos.left}px`,
            top: `${grabPos.top}px`,
            transform: `scale(${scale})`,
            cursor: cursorStyle,
          }}
        >
          <Layer ref={drawRef}>
            <KonvaImage
              image={image}
              width={contain.width}
              height={contain.height}
            />
            {data.map((e, i) => (
              <PackAiEditorItem
                key={i}
                item={e}
                index={i}
                onDelete={onDeleteItem}
                setItem={setItem}
                selectItem={selectItem}
                scale={scale}
              />
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

export default PackageAiEditor;
