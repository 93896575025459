/* eslint-disable indent */
import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, Col, message, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { postPredictVariant } from '../../apis/serve';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn2 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import EditableText from '../../components/editableText.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import Title from '../../components/title2.jsx';
import { checkAoi } from './checkAoi.js';
import Design from './design.jsx';
import style from './index.module.scss';
import HelpPng from '../../assets/img/help2.png';
import Dialog from '../../components/dialog.jsx';

function buildAoiPoints(aoi, scale = 1) {
  if (!aoi.points) return undefined;
  const [lt, rb] = aoi.points;
  lt.x = Math.round(lt.x * scale);
  lt.y = Math.round(lt.y * scale);
  rb.x = Math.round(rb.x * scale);
  rb.y = Math.round(rb.y * scale);
  const rt = { x: rb.x, y: lt.y };
  const lb = { x: lt.x, y: rb.y };
  return [lt, rt, rb, lb];
}

function buildAoi(name, nullPoints = false) {
  return {
    name,
    type: 'rect',
    points: nullPoints
      ? undefined
      : [
          { x: 0, y: 0 },
          { x: 50, y: 50 },
        ],
  };
}
function buildAois() {
  return [
    buildAoi('Brand Logo'),
    buildAoi('Variant Name'),
    buildAoi(
      'Secondary Element that helps with Brand Blocking (Optional)',
      true
    ),
    buildAoi(
      'Secondary Element that helps with Variant Differentiation (Optional)',
      true
    ),
  ];
}

function Info() {
  return (
    <div className={style.info}>
      <p>Click image to edit area of intrest.</p>
    </div>
  );
}

function useDesigns(context, setContext, unileverBrandName, setHelpVisible) {
  function setDesign(index, design) {
    setContext((c) => {
      const nc = { ...c };
      nc.aoi.unilever[index] = design;
      return nc;
    });
  }

  return context.asset.unilever.map((d, i) => {
    let dn = unileverBrandName;
    if (i === 0) {
      dn += ' Current';
    } else if (i === 1) {
      dn += ' New Design';
    } else {
      dn += ' New Design ' + i;
    }

    const bo = context.basic.businessObjective;
    if (bo === 'Competitor Benchmarking' && i === 0) {
      return null;
    } else if (bo === 'Innovation' && i > 0) {
      return null;
    } else {
      return (
        <Col key={d.id} span={6}>
          <Design
            className={style.design}
            numberOfVariantsPerDesign={context.basic.numberOfVariantsPerDesign}
            designName={dn}
            assetDesign={d}
            design={context.aoi.unilever[i]}
            setDesign={(design) => setDesign(i, design)}
            onHelpClick={() => setHelpVisible(true)}
          />
        </Col>
      );
    }
  });
}

function PackageAiView() {
  const [context, setContext] = useState({
    basic: {
      businessObjective: 'Redesign',
      numberOfOwnNewPackDesigns: 1,
      numberOfVariantsPerDesign: 1,
    },
    asset: {
      unilever: [],
      competitor1: { variants: [{}] },
      competitor2: { variants: [{}] },
    },
    aoi: {
      unilever: [],
      competitor1: { variants: [{ aois: buildAois() }] },
      competitor2: { variants: [{ aois: buildAois() }] },
    },
  });
  const [unileverBrandName, setUnileverBrandName] = useMemo(() => {
    return [
      context.basic?.unileverBrandName ?? 'Unilever Brand Name',
      (value) => {
        setContext({
          ...context,
          basic: {
            ...context.basic,
            unileverBrandName: value,
          },
        });
      },
    ];
  }, [context]);
  const [competitor1BrandName, setCompetitor1BrandName] = useMemo(() => {
    return [
      context.basic?.competitor1BrandName ?? 'Competitor 1 Brand Name',
      (value) => {
        setContext({
          ...context,
          basic: {
            ...context.basic,
            competitor1BrandName: value,
          },
        });
      },
    ];
  }, [context]);
  const [competitor2BrandName, setCompetitor2BrandName] = useMemo(() => {
    return [
      context.basic?.competitor2BrandName ?? 'Competitor 2 Brand Name',
      (value) => {
        setContext({
          ...context,
          basic: {
            ...context.basic,
            competitor2BrandName: value,
          },
        });
      },
    ];
  }, [context]);
  const [allowPublic, setAllowPublic] = useMemo(() => {
    return [
      context.asset?.allowPublic ?? true,
      (ev) => {
        setContext({
          ...context,
          asset: {
            ...context.asset,
            allowPublic: ev.target.checked,
          },
        });
      },
    ];
  }, [context]);

  function init() {
    const ctxs = sessionStorage.getItem('packageAiContext');
    if (!ctxs) {
      message.error('Please fill in the basic information first');
      window.open('/package-ai-screening-basic', '_self');
      return null;
    }
    const ctx = JSON.parse(ctxs);
    if (!ctx.aoi) {
      ctx.aoi = {};
      ctx.aoi.unilever = ctx.asset.unilever.map((d) => {
        return {
          id: d.id,
          variants: d.variants.map((v) => {
            return {
              id: v.id,
              aois: buildAois(),
            };
          }),
        };
      });
      ctx.aoi.competitor1 = {
        id: ctx.asset.competitor1.id,
        variants: ctx.asset.competitor1.variants.map((v) => {
          return {
            id: v.id,
            aois: buildAois(),
          };
        }),
      };
      ctx.aoi.competitor2 = {
        id: ctx.asset.competitor2.id,
        variants: ctx.asset.competitor2.variants.map((v) => {
          return {
            id: v.id,
            aois: buildAois(),
          };
        }),
      };
    }
    setContext(ctx);
  }
  useEffect(() => {
    init();
  }, []);

  function onSetDesignC1(design) {
    setContext((c) => {
      const nc = { ...c };
      nc.aoi.competitor1 = design;
      return nc;
    });
  }
  function onSetDesignC2(design) {
    setContext((c) => {
      const nc = { ...c };
      nc.aoi.competitor2 = design;
      return nc;
    });
  }

  const [loading, setLoading] = useState(false);

  async function postAoi(id, aois) {
    // use logo aoi
    const logoAoi = buildAoiPoints(aois[0]);
    // use brand
    const brandAoi = buildAoiPoints(aois[1]);
    // use variant 1
    const variantAoi1 = buildAoiPoints(aois[2]);
    // use variant 2
    const variantAoi2 = buildAoiPoints(aois[3]);
    await postPredictVariant({
      id,
      points: {
        logo: logoAoi,
        brand: brandAoi,
        variant: variantAoi1,
        aoi: variantAoi2,
      },
    });
  }

  const complete = useMemo(() => {
    const bo = context.basic.businessObjective;
    const aoi = context.aoi;
    const complete = [];
    // check unilever
    for (let i = 0; i < aoi.unilever.length; i++) {
      if (bo === 'Competitor Benchmarking' && i === 0) continue;
      else if (bo === 'Innovation' && i > 0) continue;
      const d = aoi.unilever[i];
      for (const v of d.variants) {
        for (const a of v.aois) {
          complete.push(checkAoi(a));
        }
      }
    }
    // check competitor1
    for (const v of aoi.competitor1.variants) {
      for (const a of v.aois) {
        complete.push(checkAoi(a));
      }
    }
    // check competitor2
    for (const v of aoi.competitor2.variants) {
      for (const a of v.aois) {
        complete.push(checkAoi(a));
      }
    }
    return complete.every((v) => v);
  }, [context]);

  async function onContinue() {
    if (loading) return;
    setLoading(true);
    try {
      // use predictPackage
      const ppRes = context.res.predictPackage;
      // post aoi
      for (let i = 0; i < context.aoi.unilever.length; i++) {
        // use design
        const d = context.aoi.unilever[i];
        for (let j = 0; j < d.variants.length; j++) {
          // use variant
          const v = d.variants[j];
          const key = `ul_design${i}_variant${j}_id`;
          const id = ppRes[key];
          if (!id) continue;
          await postAoi(id, v.aois);
        }
      }
      for (let i = 0; i < context.aoi.competitor1.variants.length; i++) {
        const v = context.aoi.competitor1.variants[i];
        const key = `c1_design1_variant${i}_id`;
        const id = ppRes[key];
        if (!id) continue;
        await postAoi(id, v.aois);
      }
      for (let i = 0; i < context.aoi.competitor2.variants.length; i++) {
        const v = context.aoi.competitor2.variants[i];
        const key = `c2_design1_variant${i}_id`;
        const id = ppRes[key];
        if (!id) continue;
        await postAoi(id, v.aois);
      }
      // update context
      const nc = {
        ...context,
        res: { ...context.res },
      };
      sessionStorage.setItem('packageAiContext', JSON.stringify(nc));
      window.open('/package-ai-screening-wait', '_self');
    } catch (err) {
      console.error('Error in onContinue', err);
      message.error('Error in onContinue');
    }
    setLoading(false);
  }

  const [helpVisible, setHelpVisible] = useState(false);

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={3} />
        <Box title="Highlight Key Design Elements" className={style.box}>
          <div className={style.form}>
            <Info />
            {!(
              context.basic.businessObjective === 'Competitor Benchmarking' &&
              context.basic.numberOfOwnNewPackDesigns === 0
            ) && (
              <>
                <Title>
                  <EditableText
                    value={unileverBrandName}
                    onChange={setUnileverBrandName}
                  />
                </Title>
                <Row gutter={12}>
                  {useDesigns(
                    context,
                    setContext,
                    unileverBrandName,
                    setHelpVisible
                  )}
                </Row>
              </>
            )}
            <Row gutter={12}>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor1BrandName}
                        onChange={setCompetitor1BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      numberOfVariantsPerDesign={
                        context.basic.numberOfVariantsPerDesign
                      }
                      designName={competitor1BrandName}
                      assetDesign={context.asset.competitor1}
                      design={context.aoi.competitor1}
                      setDesign={onSetDesignC1}
                      onHelpClick={() => setHelpVisible(true)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor2BrandName}
                        onChange={setCompetitor2BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      numberOfVariantsPerDesign={
                        context.basic.numberOfVariantsPerDesign
                      }
                      designName={competitor2BrandName}
                      assetDesign={context.asset.competitor2}
                      design={context.aoi.competitor2}
                      setDesign={onSetDesignC2}
                      onHelpClick={() => setHelpVisible(true)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={style.btnsBox}>
            <Btn2
              className={style.btn2}
              onClick={onContinue}
              suffix={<ArrowRightOutlined />}
              disabled={!complete}
            >
              CONTINUE
            </Btn2>
            <Checkbox checked={allowPublic} onChange={setAllowPublic}>
              Publish my testing results in the Sous-Chef library (visible for
              others). Deselect it for keeping results only visible to you
            </Checkbox>
          </div>
        </Box>
      </div>
      <Dialog visible={helpVisible} setVisible={setHelpVisible} hasBox={false}>
        <img src={HelpPng} alt="help" className={style.helpImg} />
      </Dialog>
      {loading && <Loading />}
    </Container>
  );
}

export default PackageAiView;
