import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import style from './intro-modal.module.scss';
import closeSvg from '../../../../assets/images/gotfl-weena/close-icon.svg';
import { Modal } from 'antd';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import searchSvg from '../../../../assets/images/gotfl-weena/search-icon.svg';
import { debounce } from '../../../concept-ai/utils/utils.js';
import Intro1 from '../../../../assets/images/trends-dial/intro-1.png';
import Intro2 from '../../../../assets/images/trends-dial/intro-2.png';
import Intro1BT from '../../../../assets/images/trends-dial/intro-1-bt.jpg';

const IntroModal = () => {

  const allItemList = [
    {
      img: Intro2,
      imgBt: Intro1BT,
      title: 'Scratch Cooking Evolvement and Recent Trends in Japan',
      date: 'May, 2023',
      context: 'The report includes the evolvement and recent trends of Scratch Cooking in Japan. We collaborated with Mintel to explore the market with the highest levels of innovation and activity in Scratch Cooking (ie the largest number of new product introduction).',
      url: 'https://unilever.sharepoint.com/sites/CMIInnovationteam/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCMIInnovationteam%2FShared%20Documents%2FGeneral%2FInnovation%20Reports%20Mintel%2FScratch%5FCooking%5FProduct%5FEvolvement%5Fin%5FJapan%5FMay2023%5FMintel%20Final%20Version%2Epdf&parent=%2Fsites%2FCMIInnovationteam%2FShared%20Documents%2FGeneral%2FInnovation%20Reports%20Mintel&p=true&ga=1'
    },
    {
      img: Intro1,
      imgBt: Intro1BT,
      title: 'Dressings Evolution and Recent Trends in the USA',
      date: 'June, 2024',
      context: 'The report includes the Dressings evolution and recent trends in the USA. We collaborated with Mintel to explore the market with the highest levels of activity in Dressings (ie the largest number of new product introduction).',
      url: 'https://unilever.sharepoint.com/sites/CMIInnovationteam/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCMIInnovationteam%2FShared%20Documents%2FGeneral%2FInnovation%20Reports%20Mintel%2FMintel%5Fx%5FUnilever%5F%2D%5FDressings%5Fin%5Fthe%5FUSA%5FJun%5F24%2Epdf&parent=%2Fsites%2FCMIInnovationteam%2FShared%20Documents%2FGeneral%2FInnovation%20Reports%20Mintel&p=true&ga=1'
    }
  ];
  const [listData, setListData] = useState(allItemList);
  const [currentItem, setCurrentItem] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = (item) => {
    setIsModalOpen(true);
    setCurrentItem(item);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onMore = (item)  => {
    window.open(item.url, '_blank');
  };

  const fnInputChange = useCallback(debounce(function(e) { 
    if (['mayonnaise', 'mayo'].includes(e.target.value.toLowerCase())) {
      setListData([allItemList[1]]);
      return;
    }
    setListData(allItemList.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
  }, 500), []);

  return (
    <>
      <div className={style.introPage}>
        <div className={classNames(style.searchWrap)}>
          <div className={classNames(style.searchBox)}>
            <img className={style.searchIcon} src={searchSvg} />
            <input placeholder='Search' onChange={fnInputChange}></input>
          </div>
        </div>
        <div className={style.introItemContainer}>
          {listData.map(item => 
            <div key={item.title} className={style.itemContent} onClick={() => handleOpen(item)}>
              <img src={item.img} alt="" />
              <div className={style.infoBox}>
                <div className={style.titleBox}>{item.title}</div>
                <div className={style.dateBox}>{item.date}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal open={isModalOpen} closeIcon={false} width='1100px' centered footer={false} className={style.customModalWrap}  destroyOnClose={true}>
        <img src={closeSvg} className={classNames(style.closeIcon)} onClick={handleClose} />
        <BorderContainer id="intro_1">
          <div className={classNames(style.contentWrap)}>
            <div className={style.imgBox}>
              <img className={style.imgbg} src={currentItem.img} alt="" />
              <div className={style.infoBox}>
                <div className={style.titleBox}>{currentItem.title}</div>
                <div className={style.dateBox}>{currentItem.date}</div>
                <img className={style.img1} src={currentItem.imgBt} alt="" />
              </div>
            </div>
            <div className={classNames(style.detailWrap)}>
              <div className={classNames(style.title)}>{currentItem?.title}</div>
              <div className={classNames(style.label)}>When :</div>
              <div className={classNames(style.desc)}>{currentItem?.date}</div>
              <div className={classNames(style.label, style.marginTop28)}>Context :</div>
              <div className={classNames(style.scrollBox)}>
                <div className={classNames(style.desc)}>{currentItem?.context}</div>
              </div>
              <div className={classNames(style.btn)} onClick={() => onMore(currentItem)}>More</div>
            </div>
          </div>
        </BorderContainer>
      </Modal>
    </>
  );
};

export default IntroModal;