import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  const showModal = (obj) => {
    setIsOpen(true);
    setModalData(obj)
  };
  const closeModal = (id) => {
    setIsOpen(false);
    setModalData({})
  };

  return (
    <ModalContext.Provider value={{ isOpen, modalData, showModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
