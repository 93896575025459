import { ConfigProvider, theme } from 'antd';
import React, { useState } from 'react';
import BackgroundPng from '../../assets/images/home/bg.png';
import Logo from './components/logo.jsx';
import Title from './components/title.jsx';
import { OccasionHomeBall } from './components/ball.jsx';
import style from './home.module.scss';
import PhotoItemsJson from '../../assets/json/photo-items.json';
import DashboardItemsJson from '../../assets/json/dashboard-items.json';

function OccasionHome() {
  const [photoItemsDisplay, setPhotoItemsDisplay] = useState(false);
  const [dashboardItemsDisplay, setDashboardItemsDisplay] = useState(false);

  function onPhotoClick() {
    setPhotoItemsDisplay(!photoItemsDisplay);
    if (dashboardItemsDisplay) setDashboardItemsDisplay(false);
  }
  function onDashboardClick() {
    setDashboardItemsDisplay(!dashboardItemsDisplay);
    if (photoItemsDisplay) setPhotoItemsDisplay(false);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.homeContainer}>
        <div className={style.homeBgBox}>
          <img src={BackgroundPng} />
          <div className={style.homeBgItem}></div>
        </div>
        <Logo />
        <Title />
        <div className={style.homeBallLine}>
          <OccasionHomeBall
            title={
              <>
                Occasions
                <br />
                Dashboard
              </>
            }
            items={DashboardItemsJson.map((e) => {
              return { ...e, url: `/occasion-dashboard?tab=${e.name}` };
            })}
            itemsDisplay={dashboardItemsDisplay}
            onClick={onDashboardClick}
          />
          <OccasionHomeBall
            title={
              <>
                Occasions
                <br />
                Photo
              </>
            }
            items={PhotoItemsJson.map((e) => {
              return { ...e, url: `/occasion-photo?category=${e.name}` };
            })}
            descAtLeft={true}
            itemsDisplay={photoItemsDisplay}
            onClick={onPhotoClick}
          />
        </div>
      </div>
    </ConfigProvider>
  );
}
export default OccasionHome;
