import { ExportOutlined, RollbackOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import Box from '../../components/box.jsx';
import {
  PackageAiBtn1 as Btn1,
  PackageAiBtn4 as Btn4,
  PackageAiBtn5 as Btn5,
} from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import style from './index.module.scss';
import { PackageAiTable as Table } from './table.jsx';
import Box2 from '../../components/box2.jsx';
import Legend from '../../components/legend.jsx';

// for debug
// import CtxJson from '../../assets/json/exampleContext.json';

const COLOR_KEYS = ['black', 'dark gray', 'light gray', 'white'];

/**
 * calculate design score
 * @param {{[key:string]:{ul: number, c1: number, c2: number}}} designScore
 * @returns {{ul: number, c1: number, c2: number}}
 */
function calculateDesignScore(designScore) {
  const score = { ul: 0, c1: 0, c2: 0 };
  let length = 0;
  for (const ck of COLOR_KEYS) {
    const s = designScore[ck];
    if (!s) continue;
    score.ul += s.ul;
    score.c1 += s.c1;
    score.c2 += s.c2;
    length += 1;
  }
  score.ul /= length;
  score.c1 /= length;
  score.c2 /= length;
  return score;
}

function PackageAiView() {
  const [data, setData] = useState({
    brandStdout: [],
    balance: [],
    logoExcite: [],
    logoClarity: [],
    logoBalance: [],
  });
  const [columns, setColumns] = useState([
    { title: 'Unilever Current Design' },
    { title: 'Unilever New Design' },
    { title: 'Competitor 1' },
    { title: 'Competitor 2' },
  ]);
  const [loading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogSrc, setDialogSrc] = useState('');
  const [dialogAlt, setDialogAlt] = useState('');
  function onShowDialog(src, alt) {
    setDialogSrc(src);
    setDialogAlt(alt);
    setDialogVisible(true);
  }

  async function init() {
    if (loading) return;
    setLoading(true);
    try {
      // for debug
      // sessionStorage.setItem('packageAiContext', JSON.stringify(CtxJson));

      const ctxs = sessionStorage.getItem('packageAiContext');
      if (!ctxs) {
        message.error('Please fill in the basic information first');
        window.open('/package-ai-screening-basic', '_self');
        return null;
      }
      const ctx = JSON.parse(ctxs);
      // use pacakage info
      const pp = ctx.res.predictPackage;
      if (!pp) return;
      const vss = ctx.res.variantSuggests;
      if (!vss) return;

      // use new column
      const nc = [];
      if (pp.ul_design0_variant0_id) {
        const { data: ulImg } = await putFileKey(pp.ul_design0_variant0);
        nc.push({
          title: pp.brand_ul,
          url: ulImg.url,
        });
      }
      if (pp.ul_design1_variant0_id) {
        const { data: ulImg } = await putFileKey(pp.ul_design1_variant0);
        if (pp.ul_design0_variant0_id) {
          nc[nc.length - 1].title = `${pp.brand_ul} Current Design`;
          nc.push({
            title: `${pp.brand_ul} New Design`,
            url: ulImg.url,
          });
        } else {
          nc.push({
            title: pp.brand_ul,
            url: ulImg.url,
          });
        }
      }
      if (pp.c1_design1_variant0_id) {
        const { data: c1Img } = await putFileKey(pp.c1_design1_variant0);
        nc.push({ title: pp.brand_c1, url: c1Img.url });
      }
      if (pp.c2_design1_variant0_id) {
        const { data: c2Img } = await putFileKey(pp.c2_design1_variant0);
        nc.push({ title: pp.brand_c2, url: c2Img.url });
      }
      setColumns(nc);

      // use new data
      const nd = {
        packageVisibility: [],
        brandStdout: [],
        balance: [],
        logoExcite: [],
        logoClarity: [],
        logoBalance: [],
      };
      const processData = (id) => {
        const vid = id.toString();
        const vv = ctx.res.variants[vid];
        if (!vv) {
          return;
        }
        // set brand
        nd.brandStdout.push(vv.vasresult.logo);
        // set logo
        nd.logoExcite.push(vv.aesresult.exciting);
        nd.logoClarity.push(vv.aesresult.clear);
        nd.logoBalance.push(vv.aesresult.balance);
        // use variant suggest
        const vsb = vss?.balance?.[vid];
        if (vsb) {
          nd.balance.push(vsb);
        }
      };
      if (pp.ul_design0_variant0_id) {
        processData(pp.ul_design0_variant0_id);
        const ds = pp.designscores.uldesign0;
        if (ds) {
          const score = calculateDesignScore(ds);
          nd.packageVisibility = [score.ul, score.c1, score.c2];
        }
      }
      if (pp.ul_design1_variant0_id) {
        processData(pp.ul_design1_variant0_id);
        if (pp.ul_design0_variant0_id) {
          nd.packageVisibility = [
            nd.packageVisibility[0],
            null,
            nd.packageVisibility[1],
            nd.packageVisibility[2],
          ];
          const ds = pp.designscores.uldesign1;
          if (ds) {
            const score = calculateDesignScore(ds);
            nd.packageVisibility[1] = score.ul;
          }
        } else {
          const ds = pp.designscores.uldesign1;
          if (ds) {
            const score = calculateDesignScore(ds);
            nd.packageVisibility = [score.ul, score.c1, score.c2];
          }
        }
      }
      if (pp.c1_design1_variant0_id) {
        processData(pp.c1_design1_variant0_id);
      }
      if (pp.c2_design1_variant0_id) {
        processData(pp.c2_design1_variant0_id);
      }
      setData(nd);
    } catch (err) {
      console.error('init error', err);
      message.error('Get data error');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, []);

  function onExport() {
    window.print();
  }

  function onStart() {
    sessionStorage.removeItem('packageAiContext');
    window.open('/package-ai-screening-basic', '_self');
  }

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={4} />
        <Box
          title={
            <div className={style.boxHeaderInner}>
              <div>Outcome</div>
              <div className={style.boxHeaderRight}>
                <Btn4 className={style.boxHeaderExport} onClick={onExport}>
                  <ExportOutlined className={style.boxHeaderBtnIcon} />
                  Export
                </Btn4>
                <a href="/package-ai-screening-deep-dive">
                  <Btn5>Overall Deep Dive</Btn5>
                </a>
              </div>
            </div>
          }
          className={style.box}
        >
          <div className={style.boxInner}>
            <Table value={data} column={columns} onImgClick={onShowDialog} />
            <div className={style.box3}>
              <Box2 className={style.box2}>
                <div className={style.box2Inner}>
                  <div className={style.box2Text1}>Action Standards:</div>
                  <div className={style.box2Text2}>
                    Pack design qualifies when there are no losses vs.
                    competition and at least one win vs eyeball competitor.
                    Consider pivot when action standard is not met.
                  </div>
                </div>
              </Box2>
              <Legend />
            </div>
            <Btn1 className={style.btnStart} onClick={onStart}>
              <RollbackOutlined className={style.boxHeaderBtnIcon} />
              Start Over
            </Btn1>
          </div>
        </Box>
        {loading && <Loading />}
        <ImgDialog
          visible={dialogVisible}
          setVisible={setDialogVisible}
          src={dialogSrc}
          alt={dialogAlt}
        />
      </div>
    </Container>
  );
}

export default PackageAiView;
