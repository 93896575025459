import service from '../../../../util/pythonApi';

export function getPackageAiAsset(query = {}, skip = 0, pageSize = 6, type) {
  return service.request({
    url: '/v1/package-ai/asset',
    method: 'get',
    params: {
      filter: JSON.stringify(query),
      type,
      skip,
      page_size: pageSize,
    },
  });
}

export function postPackageAiAsset(data) {
  return service.request({
    url: '/v1/package-ai/asset',
    method: 'post',
    data,
  });
}

export function putPackageAiAsset(id, data) {
  return service.request({
    url: `/v1/package-ai/asset/${id}`,
    method: 'put',
    data,
  });
}

export function postPackageAiAssetAdobe(id) {
  return service.request({
    url: `/v1/package-ai/asset/${id}/adobe`,
    method: 'post',
  });
}
