import { ConfigProvider, theme, Button, App } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import DashboardSvg from '../assets/images/dashboard.svg';
import LogoPng from '../assets/images/logo.png';
import PhotoSvg from '../assets/images/photo.svg';
import UserSvg from '../assets/images/user.svg';
import style from './layout.module.scss';

function OccasionLayoutHeader({ onExport }) {
  return (
    <div className={style.layoutHeader}>
      <a href="/">
        <img className={style.layoutHeaderLogo} src={LogoPng} alt="unilever" />
      </a>
      <h1>Occasions Module</h1>
      <div className={style.layoutRightBox}>
        {onExport && <Button onClick={onExport}>Export</Button>}
        <a href="/user-management">
          <div className={style.layoutUser}>
            <img src={UserSvg} alt="user" />
          </div>
        </a>
      </div>
    </div>
  );
}

function OccasionLayoutSideItem({ url, img }) {
  const location = useLocation();
  return (
    <a href={url}>
      <div
        className={`${style.layoutSideItem} ${
          location.pathname === url ? style.layoutSideItemActive : ''
        }`}
      >
        <img src={img} alt={url} />
      </div>
    </a>
  );
}

function OccasionLayoutSide() {
  return (
    <div className={style.layoutSide}>
      <OccasionLayoutSideItem url="/occasion-dashboard" img={DashboardSvg} />
      <OccasionLayoutSideItem url="/occasion-photo" img={PhotoSvg} />
    </div>
  );
}

function OccasionLayout({ children, onExport }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
        components: {
          Button: {
            defaultBg: '#0127023d',
          },
        },
      }}
    >
      <App>
        <div className={style.layoutContainer}>
          <OccasionLayoutHeader onExport={onExport} />
          <div className={style.layoutInner}>
            <OccasionLayoutSide />
            <div className={style.layoutContent}>{children}</div>
          </div>
        </div>
      </App>
    </ConfigProvider>
  );
}
export default OccasionLayout;
