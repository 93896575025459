import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import BalanceSvg from '../../assets/img/balance.svg';
import BallPng from '../../assets/img/ball.png';
import ClaritySvg from '../../assets/img/clarity.svg';
import ExcitementSvg from '../../assets/img/excitement.svg';
import Box from '../../components/box2.jsx';
import ColorCoding from '../../components/colorCoding.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Legend from '../../components/legend.jsx';
import Loading from '../../components/loading.jsx';
import style from './appeal.module.scss';
import Table from './table.jsx';

function colorValue(val, thresholdFn) {
  return <ColorCoding colorValue={thresholdFn(val)}>{val}</ColorCoding>;
}

function Ball({ className = '', icon, title, content = '' }) {
  return (
    <div className={`${style.ball} ${className}`}>
      <img className={style.ballBg} src={BallPng} />
      <img className={style.ballIcon} src={icon} alt={title} />
      <div className={style.ballTitle}>{title}</div>
      <Box className={style.ballTooltip}>
        <div className={style.ballTooltipInner}>
          <div className={style.ballTooltipTitle}>{title}</div>
          {content}
        </div>
      </Box>
    </div>
  );
}

function ImageItem({ className = '', title = '', url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }
  return (
    <div className={className}>
      {/* <div className={style.imageItemTitle}>{title}</div> */}
      <div className={style.imageItemContent} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // table 1
  const [column, setColumn] = useState([
    '',
    'Our Brand Current',
    'Our Brand New Design',
    'Competitor 1',
    'Competitor 2',
  ]);
  const [value, setValue] = useState([
    ['Design Excitement', 0, 0, 0, 0],
    ['Design Clarity', 0, 0, 0, 0],
    ['Design Symmetry', 0, 0, 0, 0],
  ]);
  // image
  const [data2, setData2] = useState([]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  function onImgClick(url) {
    setDialogVisible(true);
    setDialogUrl(url);
  }

  const [loading, setLoading] = useState(true);
  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const aesImg = ctx?.res?.aesImg;
      if (!aesImg) return;

      const columns = [''];
      const values = [
        ['Design Excitement'],
        ['Design Clarity'],
        ['Design Symmetry'],
      ];
      const processAes = (name, aes) => {
        if (aes) {
          columns.push(name);
          values[0].push(
            colorValue(aes.exciting, (val) => {
              if (val > 40 && val < 60) return 3;
              else if (val > 25 && val < 75) return 2;
              else return 1;
            })
          );
          values[1].push(
            colorValue(aes.clear, (val) => {
              if (val > 75) return 3;
              else if (val > 50) return 2;
              else return 1;
            })
          );
          values[2].push(
            colorValue(aes.balance, (val) => {
              if (val > 75) return 3;
              else if (val > 60) return 2;
              else return 1;
            })
          );
        }
      };

      // ul brand
      const uld0v = variants[pp.ul_design0_variant0_id];
      processAes(`${pp.brand_ul} Current`, uld0v?.aesresult);
      const uld1v = variants[pp.ul_design1_variant0_id];
      processAes(`${pp.brand_ul} New Design`, uld1v?.aesresult);
      const uld2v = variants[pp.ul_design2_variant0_id];
      processAes(`${pp.brand_ul} New Design 2`, uld2v?.aesresult);
      const uld3v = variants[pp.ul_design3_variant0_id];
      processAes(`${pp.brand_ul} New Design 3`, uld3v?.aesresult);
      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      processAes(`${pp.brand_c1}`, c1d1v?.aesresult);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      processAes(`${pp.brand_c2}`, c2d1v?.aesresult);

      setColumn(columns);
      setValue(values);

      const data2 = [];
      const processValue = async (name, fileKey) => {
        if (!fileKey) return;
        const { data } = await putFileKey(fileKey);
        data2.push({ title: name, url: data.url });
      };

      // set ul data
      const uld0 = aesImg['current design'];
      await processValue(`${pp.brand_ul} Current`, uld0);
      const uld1 = aesImg['new design'];
      await processValue(`${pp.brand_ul} New Design`, uld1);
      setData2(data2);
    } catch (err) {
      console.error('init error', err);
      message.error('Failed to load data');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  return (
    <>
      {loading && <Loading />}
      <div className={style.box2}>
        <Ball
          icon={ExcitementSvg}
          title="Design Excitement"
          content="The ‘Excitement Score’ measures the intensity of a design’s colour combination, helping to predict viewer focus and comprehension. Scores range from 0 to 100. High scores (>75) suggest a design that might grab attention initially, but then might also overwhelm people, while low scores (<25) indicate a plain design that may not capture attention. A balanced score between 40 and 60 is considered excellent. Improving the results: To improve Excitement scores, use rich visuals, ensuring a balance between colours and white space, avoiding overly calm or overwhelming design colour themes."
        />
        <Ball
          icon={ClaritySvg}
          title="Design Clarity"
          content="The ‘Clarity Score’ measures the number of regions, corners, and features in an image, helping predict visual clarity and appeal. Scores range from 0 to 100. High scores (>75) indicate a clean, simple, and clear design which increases its perception as an attractive design, while low scores (<50) suggest a confusing and cluttered design which makes less people perceive it as attractive. Improving the results: To improve clarity in design, reduce clutter by minimizing unnecessary text, using more images, increasing whitespace, and organizing content into distinguishable blocks. This reduces cognitive load, ensuring viewers focus on key elements."
        />
        <Ball
          icon={BalanceSvg}
          title="Design Symmetry"
          content="The ‘Symmetry Score’ measures a design’s symmetry, which contributes to a design's appeal. This algorithm checks for colour symmetry and intensity and gives a score ranging from 0 to 100. High scores (>75) mean the design is balanced and harmonic. Low scores (<60) mean it’s unbalanced and lopsided. Improving the results: To improve design symmetry, limit your palette to two or three base colours, and add white space around elements to make them stand out. Ensure consistent fonts and typography and connect all elements cohesively."
        />
      </div>
      <div className={style.box1}>
        <Table className={style.table} labels={column} values={value} />
        <div className={style.legendBox}>
          <Legend />
        </div>
      </div>
      {data2.map((item, index) => {
        return (
          <ImageItem
            key={`${item.title}-${index}`}
            className={style.box11Item}
            title={item.title}
            url={item.url}
            onClick={onImgClick}
          />
        );
      })}
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogUrl}
      />
    </>
  );
}

export default PackageAiBody;
