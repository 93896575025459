import React from 'react';
import style from './container.module.scss';
import Dropdown from './dropdown.jsx';

function Step({ index, children, step, url, onClick }) {
  function oc() {
    if (index < step) {
      onClick && onClick(url);
    }
  }

  return (
    <div
      className={`${style.step} ${index <= step ? style.stepActive : ''} ${
        index < step ? style.stepClickable : ''
      }`}
      onClick={oc}
    >
      <div className={style.stepIndex}>{index}</div>
      <div className={style.stepText}>{children}</div>
    </div>
  );
}

function PackageAiContainer({ children, step }) {
  function onStepClick(url) {
    window.open(url, '_self');
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <a href="/package-ai">
          <div className={style.headerTitle}>Package AI</div>
        </a>
        <div className={style.headerContent}>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step
              index={1}
              step={step}
              url="/package-ai-generation"
              onClick={onStepClick}
            >
              Choose your Base Pack
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step
              index={2}
              step={step}
              url="/package-ai-generation2"
              onClick={onStepClick}
            >
              Edit with Easy AI
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step
              index={3}
              step={step}
              url="/package-ai-generation3"
              onClick={onStepClick}
            >
              Update Text and Logo
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step
              index={4}
              step={step}
              url="/package-ai-generation4"
              onClick={onStepClick}
            >
              Rendering
            </Step>
          </Dropdown>
        </div>
      </div>
      <div className={style.body}>{children}</div>
    </div>
  );
}

export default PackageAiContainer;
