import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './detail-popup.module.scss';
import backHomeSvg from '../../../../assets/images/gotfl-weena/back-home.svg';
import uploadSvg from '../../../../assets/images/gotfl-weena/upload.svg';
import arrowRightGreenSvg from '../../../../assets/images/gotfl-weena/arrow-right-green.svg';
import rightTopSvg from '../../../../assets/images/gotfl-weena/right-top.svg';
import headerBg from '../../../../assets/images/gotfl-weena/header_bg.png';
import headerBg2 from '../../../../assets/images/gotfl-weena/header_bg_2.png';

import { ExclamationCircleFilled } from '@ant-design/icons';
import BorderContainer from '../../../../components/border-container/border-container.jsx';
import FileCard from '../file-card/index.jsx';
import PreviewPopup from '../preview-popup/preview-popup.jsx';
import { Modal } from 'antd';
import useBasic from '../../hooks/useBasic.js';
import { useRoot } from '../../hooks/useRoot.jsx';
import { deleteResource } from '../../api/request.js';
import { isWinDpr1 } from '../../../../util/utils.jsx';

const { confirm } = Modal;

const DetailPopup = ({open, onClose, id}) => {
  const { fnOpenEditPopup, refreshFlag, fnOpenEditPopupForModify, isGotflWeenaAdmin } = useRoot();
  const {resourcesDeail: detail, fnGetResourceByShopId} = useBasic();
  const [openPreviewWin, setOpenPreviewWin] = useState(false);
  const [type, setType] = useState('picture');
  const [sourceInfo, setSourceInfo] = useState({});

  useEffect(() => {
    if(open) {
      fnGetResourceByShopId(id);
    }
  }, [id, open, refreshFlag]);

  const fnOpenPreviewWin = (sourceItem) => {
    setSourceInfo(sourceItem);
    setOpenPreviewWin(true);
  };

  const fnClosePreviewWin = () => {
    setOpenPreviewWin(false);
  };

  const fnPreviewDocument = (sourceItem) => {
    window.open(sourceItem.url, '_blank');
  };

  // open edit resource
  const fnEditResource = (e, id, type) => {
    e.stopPropagation();
    try {
      fnOpenEditPopupForModify(id, type);
    } catch(error) {
      console.log(error);
    }
  };

  // delete resource
  const fnDelResource = (e, itemId) => {
    e.stopPropagation();
    confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: async () => {
        try {
          await deleteResource(itemId);
          fnGetResourceByShopId(id);
        } catch(error) {
          console.log(error);
        }
      },
      onCancel() {
      },
    });
  };

  // scroll ctrl
  const [showLRByFile, setShowLRByFile] = useState('');
  const [showLRByVideo, setShowLRByVideo] = useState('');

  useEffect(() => {
    if(open && detail) {
      setTimeout(() => {
        const scrollWidthByVideo = document.querySelector('#picture_video').scrollWidth;
        const offsetWidthByVideo = document.querySelector('#picture_video').offsetWidth;
        if(scrollWidthByVideo <= offsetWidthByVideo) {
          setShowLRByVideo('');
        } else {
          setShowLRByVideo('right');
        }

        const scrollWidthByDocument = document.querySelector('#document').scrollWidth;
        const offsetWidthByDocument = document.querySelector('#document').offsetWidth;
        if(scrollWidthByDocument <= offsetWidthByDocument) {
          setShowLRByFile('');
        } else {
          setShowLRByFile('right');
        }
      }, 1000);
    } else {
      setShowLRByFile('');
      setShowLRByVideo('');
    }
  }, [open, detail]);

  // scroll bar for click
  const fnScrollTo = (nodeIdStr, type) => {
    const scrollLeft = document.querySelector('#'+nodeIdStr).scrollLeft;

    document.querySelector('#'+nodeIdStr).scrollTo({
      left: scrollLeft + (400 * type),
      behavior: 'smooth',
    });
  };

  const fnScrollListener = (e, type) => {
    const {scrollLeft, offsetWidth, scrollWidth} = e.target;
    if(scrollLeft === 0) {
      if(type === 'document') {
        setShowLRByFile('right');
      } else {
        setShowLRByVideo('right');
      }
    } else {
      if((offsetWidth + scrollLeft) >= scrollWidth) {
        if(type === 'document') {
          setShowLRByFile('left');
        } else {
          setShowLRByVideo('left');
        }
      } else {
        if(type === 'document') {
          setShowLRByFile('left,right');
        } else {
          setShowLRByVideo('left,right');
        }
      }
    }
  };

  return <Modal open={open} closeIcon={false} width="1100px" centered={isWinDpr1()} footer={false} className={style.customModalWrap}  destroyOnClose={true}>
    <BorderContainer id="intro_1" showTL={false}>
      <div className={classNames(style.headerBox)}>
        {
          // <img src={rightTopSvg} style={{position: 'absolute', top: '-4px', left: '-4px'}}></img>
        }
        <div className={classNames(style.backBox)} onClick={onClose}>
          <img src={backHomeSvg} style={{width: '24px', height: '24px'}} ></img>
          <div className={classNames(style.pageTitle)}>Back</div>
        </div>
      </div>
      <div className={classNames(style.contentWrap)}>
        <div className={classNames(style.ctrlBox)}>
          <img src={headerBg} style={{position: 'absolute', top: '0px', left: '0px', height: '52px'}}></img>
          <div className={classNames(style.leftBox)}>
            <div className={classNames(style.uploadBtn, style.active)}>
              <span className={classNames(style.active)}>File</span>
            </div>
          </div>
          <div className={classNames(style.rightBox)}>
            {
              isGotflWeenaAdmin && <div className={classNames(style.uploadBtn)} onClick={() => fnOpenEditPopup(true, 'document', id)}>
                <img src={uploadSvg}></img>
                <span>Upload</span>
              </div>
            }
          </div>
        </div>
        <div className={classNames(style.fileBoxWrap)}>
          {
            showLRByFile.includes('left') && <div className={classNames(style.imgMoreBox, style.preBtn)} onClick={() =>fnScrollTo('document', -1)}>
              <img src={arrowRightGreenSvg}></img>
            </div>
          }
          <div className={classNames(style.fileBox)} id="document" onScroll={(e) => fnScrollListener(e, 'document')}>
            {
              detail?.documentList?.map((item, index) => <FileCard key={index} onPreview={() => fnPreviewDocument(item)} onDelete={(e) => fnDelResource(e, item.id)} onEdit={(e) => fnEditResource(e, item.id, 'document')} detail={item} isDownload={false} width={index === 0 ? '400px': '160px'}></FileCard>)
            }
            {
              detail?.documentList?.length === 0 && <div className={classNames(style.emptyBlock)}></div>
            }
          </div>
          {
            showLRByFile.includes('right') && <div className={classNames(style.imgMoreBox)} onClick={() =>fnScrollTo('document', 1)}>
              <img src={arrowRightGreenSvg}></img>
            </div>
          }
        </div>
        <div style={{height: '48px'}}></div>

        <div className={classNames(style.ctrlBox)}>
          <img src={headerBg2} style={{position: 'absolute', top: '0px', left: '0px', height: '52px'}}></img>
          <div className={classNames(style.leftBox)}>
            <div className={classNames(style.uploadBtn, type === 'picture' && style.active)} onClick={() => setType('picture')}>
              <span>Picture</span>
            </div>
            <div className={classNames(style.uploadBtn, type === 'video' && style.active)} onClick={() => setType('video')}>
              <span>Video</span>
            </div>
          </div>
          <div className={classNames(style.rightBox)}>
            {
              isGotflWeenaAdmin && <div className={classNames(style.uploadBtn)}  onClick={() => fnOpenEditPopup(true, type, id)}>
                <img src={uploadSvg}></img>
                <span>Upload</span>
              </div>
            }
          </div>
        </div>
        <div className={classNames(style.fileBoxWrap)}>
          {
            showLRByVideo.includes('left') && <div className={classNames(style.imgMoreBox, style.preBtn)} onClick={() =>fnScrollTo('picture_video', -1)}>
              <img src={arrowRightGreenSvg}></img>
            </div>
          }
          <div className={classNames(style.fileBox)} id="picture_video" onScroll={(e) => fnScrollListener(e, 'picture_video')}>
            {
              (type === 'picture' ? detail?.pictureList: detail?.videoList)?.map((item, index) => <FileCard key={index}  onDelete={(e) => fnDelResource(e, item.id)} onEdit={(e) => fnEditResource(e, item.id, type)} detail={item} onPreview={() => fnOpenPreviewWin(item)} width={index === 0 ? '400px': '160px'}></FileCard>)
            }
            {
              ((type === 'picture' ? detail?.pictureList: detail?.videoList) || []).length === 0 && <div className={classNames(style.emptyBlock)}></div>
            }
          </div>
          {
            showLRByVideo.includes('right') && <div className={classNames(style.imgMoreBox)} onClick={() =>fnScrollTo('picture_video', 1)}>
              <img src={arrowRightGreenSvg}></img>
            </div>
          }
        </div>

      </div>
    </BorderContainer>

    <PreviewPopup detail={sourceInfo} open={openPreviewWin} onClose={fnClosePreviewWin}></PreviewPopup>
  </Modal>;
};

export default DetailPopup;