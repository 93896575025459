import { Empty, Spin, Tooltip } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../components/context/context.jsx';
import styles from './table.module.scss';

import { LoadingOutlined } from '@ant-design/icons';
import ImageLazy from '../../components/image-lazy/image-lazy.jsx';

const Table = ({ header, data, onAvgClick, loading, originData, small }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  const { showModal } = useContext(ModalContext);
  const [updatedData, setUpdatedData] = useState(data);

  const handleHeaderClick = (key) => {
    onAvgClick(key);
  };

  const handleImgClick = (id) => {
    const obj = originData.find((item) => item.sn === id);
    showModal(obj);
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  return (
    <div
      className={classNames(styles.tableContainer, { [styles.small]: small })}
    >
      <div className={styles.header}>
        <div className={classNames(styles.cell)}>
          <div className={styles.imgRow}>
            <div className={styles.imgWrap}></div>
          </div>
        </div>
        {header.map((item, index) => (
          <div className={classNames(styles.headerCell)} key={index}>
            <div className={styles.headerWrap}>
              <Tooltip placement="top" color={'#015719'} title={item.tips}>
                <div className={styles.tipsWrap}></div>
              </Tooltip>
              <div className={styles.headerTxt}>{item.title}</div>
            </div>
            <div className={styles.headerAvg}>
              {`Avg: ${item.value}%`}
              <div
                className={classNames(
                  styles.btnAvg,
                  item.isSortAsc ? styles.desc : '',
                  item.isSortDesc ? styles.asc : ''
                )}
                onClick={() => handleHeaderClick(item.key)}
              >
                <s></s>
              </div>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <Spin indicator={antIcon}>
          <div className={styles.placeholder}></div>
        </Spin>
      )}
      {!loading &&
        (data.length === 0 ? (
          <div className={classNames(styles.placeholder, styles.empty)}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          <div className={styles.body}>
            {updatedData.map((row) => (
              <div
                className={styles.row}
                key={row.id}
                onClick={() => {
                  handleImgClick(row.id);
                }}
              >
                <div className={styles.cell}>
                  <div className={styles.imgRow}>
                    <ImageLazy sn={row.id} market={row.market} />
                    <div className={styles.text}>{row.name}</div>
                    <div className={styles.imgRowBg}></div>
                  </div>
                </div>
                {row.value.map((value, index) => (
                  <div
                    className={classNames(styles.cell, styles.valueTxt)}
                    key={index}
                  >{`${value}%`}</div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

Table.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      tips: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      isSortDesc: PropTypes.bool.isRequired,
      isSortAsc: PropTypes.bool.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      market: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.array.isRequired,
    })
  ).isRequired,
  onAvgClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  originData: PropTypes.array.isRequired,
  small: PropTypes.bool,
};

export default Table;
