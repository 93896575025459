import { ConfigProvider, Dropdown, Select, Switch, theme } from 'antd';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import exportToExcel from '../../api/export.js';
import { getProduct, getImageById, getFilter } from '../../api/index.js';
import BarChartComponent from '../../components/bar-chart/bar.jsx';
import BorderBox from '../../components/box/index.jsx';
import ImgDialog from '../../components/img-dialog/index.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import VideoBg from '../../components/video-bg/bg.jsx';
import style from './competitive.module.scss';

function CompetitivePage() {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  const [isInitialLoad, setIsInitialLoad] = useState(true); // 用于追踪是否是首次加载

  const [searchParams] = useSearchParams();
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const [categoryValue, setCategory] = useState(null);
  const [marketValue, setMarket] = useState(null);
  const [demographicsValue, setDemographics] = useState(null);

  const [imgList, setImgList] = useState([]);

  const [chartData, setChartData] = useState([]);

  const [sortKey, setSortKey] = useState('brand_logo_visibility');
  const [sort, setSort] = useState('desc');

  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsMarket, setOptionsMarket] = useState([]);

  const getOptionsData = async () => {
    const param = {
      market: [marketValue],
      company: [],
      category: [],
      brand: [],
    };
    const { data: cd } = await getFilter('category', param);
    if (cd) setOptionsCategory(cd.map((e) => ({ label: e, value: e })));
    const { data: md } = await getFilter('market', param);
    if (md) setOptionsMarket(md.map((e) => ({ label: e, value: e })));
  };

  // eslint-disable-next-line no-unused-vars
  const [imgLoading, setImgLoading] = useState(true);

  // 图片缓存
  const [imgCache, setImgCache] = useState(new Map());

  // 本地排序
  const sortData = (array, key, order) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const [originData, setOriginData] = useState([]);
  const fetchData = async (sort, order) => {
    setIsLoading(true);
    if (!categoryValue || !marketValue === null) {
      return;
    }
    const { data: resultArr } = await getProduct(
      {
        market: [marketValue],
        company: [],
        category: [categoryValue],
        brand: [],
      },
      {
        generation: [],
        gender: [],
        user_vs_nouser: [],
        social_class: [demographicsValue],
      },
      '',
      sort,
      order
    );
    const setImageLoader = resultArr.map((item) => {
      item.isLoadingImg = true;
      return item;
    });
    setOriginData(setImageLoader);
    const dataList = buildChartData(setImageLoader);
    setChartData(dataList);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchDataAndBuild = async () => {
      // 只有在状态变化时才调用 fetchData
      if (categoryValue || marketValue || demographicsValue) {
        await fetchData(sortKey, sort);
      }
    };
    // 重置图表数据和图片列表
    setChartData([]);
    setImgList([]);
    if (!isInitialLoad) {
      fetchDataAndBuild();
    }
  }, [categoryValue, marketValue, demographicsValue]);

  useEffect(() => {
    const fetchImages = async () => {
      setImgLoading(true);
      const newList = [...originData];
      for (const item of originData) {
        if (!imgCache.has(item.sn)) {
          const url = await getImageById(item.sn, item.market);
          setImgCache(imgCache.set(item.sn, url));
        }
      }
      newList.forEach((item) => {
        item.img = imgCache.get(item.sn);
        item.isLoadingImg = false;
      });
      setChartData(buildChartData(newList));
      setImgLoading(false);
    };
    fetchImages();
  }, [originData, imgCache]);

  // 排序
  useEffect(() => {
    const localSortDataList = async () => {
      if (originData.length > 0) {
        const sortedData = sortData(originData, sortKey, sort);
        setOriginData(sortedData);
        const dataList = buildChartData(sortedData);
        setChartData(dataList);
      }
    };
    localSortDataList();
  }, [sort, sortKey]);

  useEffect(() => {
    getOptionsData();
    const market = searchParams.get('market');
    const category = searchParams.get('category');
    // 检查并设置 market 和 category 值
    if (market) {
      setMarket(market);
    }
    if (category) {
      setCategory(category);
    }
    setIsInitialLoad(false);
  }, []);

  // chart对象
  const chartRefs = {
    0: useRef(null),
    1: useRef(null),
    2: useRef(null),
  };

  // filter事件
  const handleFilter = (newValue, key) => {
    if (key === 1) {
      setCategory(newValue);
    }
    if (key === 2) {
      setMarket(newValue);
    }
    if (key === 3) {
      setDemographics(newValue);
    }
  };

  const handleSort = async (key, order) => {
    setSortKey(key);
    if (order === 'asc') {
      setSort('desc');
    } else {
      setSort('asc');
    }
  };

  const markColors = (score) => {
    let color;
    if (Number(score) > 70) {
      color = '#00A32E';
    }
    if (Number(score) >= 41 && Number(score) <= 70) {
      color = '#a39300';
    }
    if (Number(score) < 41) {
      color = '#d70728';
    }
    return color;
  };

  // build chartdata
  const buildChartData = (data) => {
    const dataList = [...data];
    const chartBrand = [];
    const chartAppeal = [];
    const chartIntent = [];
    for (const item of dataList) {
      chartBrand.push({
        name: item.display_name,
        value: item.brand_logo_visibility,
        icon: item.img,
        color: markColors(item.brand_logo_visibility),
      });
      chartAppeal.push({
        name: item.display_name,
        value: item.appeal,
        icon: item.img,
        color: markColors(item.appeal),
      });
      chartIntent.push({
        name: item.display_name,
        value: item.upi,
        icon: item.img,
        color: markColors(item.upi),
      });
    }

    const result = [
      {
        title: 'Brand Visibility',
        key: 'brand_logo_visibility',
        chart: chartBrand,
        isOrderBy: sortKey === 'brand_logo_visibility',
        order: sort,
      },
      {
        title: 'Appeal',
        key: 'appeal',
        chart: chartAppeal,
        isOrderBy: sortKey === 'appeal',
        order: sort,
      },
      {
        title: 'Unpriced Purchase Intent',
        key: 'upi',
        chart: chartIntent,
        isOrderBy: sortKey === 'upi',
        order: sort,
      },
    ];
    const newImgList = [];
    for (const item of result) {
      if (item.isOrderBy) {
        for (const data of item.chart) {
          newImgList.push({ src: data.icon, alt: data.name });
        }
      }
    }

    setImgList(newImgList);

    return result;
  };

  // 是否展示数据
  const [showValues, setShowValues] = useState(true);

  // 下拉展示控制
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // 下拉展示控制
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setDropdownOpen(nextOpen);
    }
  };

  // 开关事件
  const handleSwitchChange = () => {
    setShowValues((checked) => !checked);
  };

  // 导出图片
  const exportChart = () => {
    const list = [
      { name: 'Brand Visibility', chart: null },
      { name: 'Appeal', chart: null },
      { name: 'Unpriced Purchase Intent', chart: null },
    ];
    const arr = Object.values(chartRefs);
    const chartList = list.map((item, index) => {
      return {
        ...item,
        chart: arr[index],
      };
    });
    chartList.forEach((item) => {
      const chartRef = item.chart.current;
      if (chartRef) {
        html2canvas(chartRef).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `chart-${item.name}.png`;
          link.click();
        });
      }
    });
  };

  // 导出excel
  const exportAllToExcel = () => {
    exportToExcel(chartData, 'Pack AI Performance');
  };

  // 创建下拉菜单
  const createDropItems = () => [
    {
      key: 1,
      label: (
        <>
          <span className={style.dropDownLabel}>Show Data Labels</span>
          <Switch
            checked={showValues}
            onChange={(checked) => handleSwitchChange(checked)}
          />
        </>
      ),
    },
    {
      key: 2,
      label: <span onClick={() => exportChart()}>Save as Png</span>,
    },
    {
      key: 3,
      label: <span onClick={() => exportAllToExcel()}>Export as Excel</span>,
    },
  ];

  // image dialog
  const [imgDialogVisible, setImgDialogVisible] = useState(false);
  const [imgDialogSrc, setImgDialogSrc] = useState();
  const [imgDialogAlt, setImgDialogAlt] = useState();
  const onImgClick = useCallback(
    (src, alt) => {
      setImgDialogSrc(src);
      setImgDialogAlt(alt);
      setImgDialogVisible(true);
    },
    [setImgDialogSrc, setImgDialogAlt, setImgDialogVisible]
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        components: {
          Select: {},
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.box1}>
        <TopBar />
        <div className={style.box11}>
          <BorderBox className={style.box111} loading={isLoading}>
            <div className={style.box1111}>
              {/* 搜索 */}
              <div className={style.searchWrap}>
                <div className={style.searchTitle}>
                  <s className={style.searchIcon}></s>
                  <span className={style.searchText}>Filter</span>
                </div>
                <h5 className={style.searchLabel}>Market</h5>
                <div className={style.myInput}>
                  <Select
                    placeholder="Market"
                    onChange={(newValue) => {
                      handleFilter(newValue, 2);
                    }}
                    value={marketValue}
                    options={optionsMarket}
                  />
                </div>
                <h5 className={style.searchLabel}>Category</h5>
                <div className={style.myInput}>
                  <Select
                    placeholder="Category"
                    onChange={(newValue) => {
                      handleFilter(newValue, 1);
                    }}
                    value={categoryValue}
                    filterOption={filterOption}
                    options={optionsCategory}
                  />
                </div>
              </div>
              <div className={style.border} />
              {/* 图表 */}
              <div className={style.chartWrap}>
                {!isLoading && (
                  <div className={style.chartInner}>
                    <div className={style.chartContent}>
                      <div className={style.imgWrap}>
                        {imgList.map((item, index) => (
                          <div
                            className={style.imgBox}
                            key={`${item.alt} ${index}`}
                          >
                            <img
                              src={item.src}
                              loading="lazy"
                              onClick={() => onImgClick(item.src, item.alt)}
                            />
                            <h6 title={item.alt}>{item.alt}</h6>
                          </div>
                        ))}
                      </div>
                      <div className={style.chartFlex}>
                        <div className={style.chartFlexInner}>
                          {chartData.map((item, i) => (
                            <div
                              key={i}
                              className={classNames(style.chartItem, {
                                [style.active]: item.isOrderBy,
                                [style.borderRight]: i !== 2,
                              })}
                            >
                              <div className={style.chartHeader}>
                                <span
                                  className={style.title}
                                  onClick={() =>
                                    handleSort(item.key, item.order)
                                  }
                                >
                                  {item.title}
                                </span>
                                <s
                                  className={classNames(style.desc, {
                                    [style.asc]:
                                      item.isOrderBy && item.order === 'asc',
                                  })}
                                  onClick={() =>
                                    handleSort(item.key, item.order)
                                  }
                                ></s>
                              </div>
                              <div className={style.content} ref={chartRefs[i]}>
                                <BarChartComponent
                                  data={item.chart}
                                  showValue={showValues}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={style.opt}>
                        <Dropdown
                          menu={{ items: createDropItems(1) }}
                          trigger={['click']}
                          placement="bottomRight"
                          open={dropdownOpen}
                          onOpenChange={handleOpenChange}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className={style.iconMore}
                          ></a>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </BorderBox>
        </div>
        <VideoBg />
      </div>
      <ImgDialog
        visible={imgDialogVisible}
        setVisible={setImgDialogVisible}
        src={imgDialogSrc}
        alt={imgDialogAlt}
      />
    </ConfigProvider>
  );
}

export default CompetitivePage;
